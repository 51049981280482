import app from "../../main";
//国际化
import * as i18n from '@/i18n/index';

const enterprise = {

    namespaced: true,

    state: {

        //可供切换的企业列表
        enterpriseList: [
            {
                id: -1,
                name: i18n.t("main.text11")
            }
        ]

    },

    getters: {},

    mutations: {

        SET_ENTERPRISE_LIST: (state, enterpriseList) => {
            state.enterpriseList = app.$config.deepCopy(enterpriseList);
        }

    },

    actions: {

        /**
         * 查询当前用户的所有处于启用状态的关系的企业列表名称
         * @param {*} param0
         * @returns
         */
        async queryEnterpriseByUserId({commit}) {
            await app.$api.enterprise.queryEnterpriseByUserId().then(res => {
                let list = [];
                list.push({
                    id: -1,
                    name: i18n.t("main.text11")
                })
                if (!res.result) {
                    commit('SET_ENTERPRISE_LIST', list);
                    return;
                }
                for (let i = 0; i < res.result.length; i++) {
                    list.push({
                        id: res.result[i].enterpriseId,
                        name: res.result[i].fullName
                    })
                }
                commit('SET_ENTERPRISE_LIST', list);
            }, err => {
                app.$dialog.showMessage(err.resultMsg, app.$config.TOAST_ERROR);
            });
        },

        /**
         * 新增企业认证
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async addEnterprise({commit}, data) {
            return await app.$api.enterprise.addEnterprise(data);
        },

        /**
         * 企业管理后台分页查询接口
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async queryEnterprise({commit}, data) {
            return await app.$api.enterprise.queryEnterprise(data);
        },

        /**
         * 根据企业ID查询企业详情
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async queryEnterpriseDetailById({commit}, data) {
            return await app.$api.enterprise.queryEnterpriseDetailById(data);
        },

        /**
         * iot平台端企业列表查询
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async queryEnterpriseToIotpager({commit}, data) {
            return await app.$api.enterprise.queryEnterpriseToIotpager(data);
        },

        /**
         * 企业移交
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async transferEnterprise({commit}, data) {
            return await app.$api.enterprise.transferEnterprise(data);
        },

        /**
         * 企业切换
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async changeEnterprise({commit}, data) {
            return await app.$api.user.changeEnterprise(data);
        },

        /**
         * 企业申请认证信息修改–修改后转为待审核状态
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async enterpriseModification({commit}, data) {
            return await app.$api.enterprise.enterpriseModification(data);
        }

    }

}

export default enterprise;
