import app from "../../main";

const effect = {

    namespaced: true,

    state: {

        //新增功能定义弹窗开关
        addEffectDialogVisible: false,

        //修改功能定义弹窗开关
        modifyEffectDialogVisible: false,

        //单个功能定义信息
        effectListItem: {},

        //属性弹窗开关
        attributeDialogVisible: false,

        //属性弹窗类型（0：新增，1：修改，2：详情）
        attributeDialogType: 0,

        //功能弹窗类型（0：修改，1：详情）
        effectDialogType: 0,

        //单个属性信息
        attributeMsgItem: {},

        //添加标准功能弹窗开关
        addStandardFunctionsDialogVisible: false,

    },

    getters: {},

    mutations: {

        SET_ADD_EFFECT_DIALOG_VISIBLE: (state, addEffectDialogVisible) => {
            state.addEffectDialogVisible = addEffectDialogVisible;
        },

        SET_MODIFY_EFFECT_DIALOG_VISIBLE: (state, modifyEffectDialogVisible) => {
            state.modifyEffectDialogVisible = modifyEffectDialogVisible;
        },

        SET_EFFECT_LIST_ITEM: (state, effectListItem) => {
            state.effectListItem = app.$config.deepCopy(effectListItem)
        },

        SET_ATTRIBUTE_DIALOG_VISIBLE: (state, attributeDialogVisible) => {
            state.attributeDialogVisible = attributeDialogVisible;
        },

        SET_ATTRIBUTE_DIALOG_TYPE: (state, attributeDialogType) => {
            state.attributeDialogType = attributeDialogType;
        },

        SET_EFFECT_DIALOG_TYPE: (state, effectDialogType) => {
            state.effectDialogType = effectDialogType;
        },

        SET_ATTRIBUTE_MSG_ITEM: (state, attributeMsgItem) => {
            state.attributeMsgItem = app.$config.deepCopy(attributeMsgItem);
        },

        SET_ADD_STANDARD_FUNCTIONS_DIALOG_VISIBLE: (state, addStandardFunctionsDialogVisible) => {
            state.addStandardFunctionsDialogVisible = addStandardFunctionsDialogVisible;
        }

    },

    actions: {

        closeAddEffectDialog({commit}) {
            commit("SET_ADD_EFFECT_DIALOG_VISIBLE", false);
        },

        closeAttributeDialog({commit}) {
            commit("SET_ATTRIBUTE_DIALOG_VISIBLE", false);
            commit("SET_ATTRIBUTE_MSG_ITEM", {});
        },

        closeModifyEffectDialog({commit}) {
            commit("SET_MODIFY_EFFECT_DIALOG_VISIBLE", false);
            commit("SET_EFFECT_LIST_ITEM", {});
        },

        closeAddStandardFunctionsDialog({commit}) {
            commit("SET_ADD_STANDARD_FUNCTIONS_DIALOG_VISIBLE", false);
        },

        /**
         * 新增属性
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async addProductAttribute({commit}, data) {
            return await app.$api.product.addProductAttribute(data);
        },

        /**
         * 修改功能属性
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async modifyProductAttribute({commit}, data) {
            return await app.$api.product.modifyProductAttribute(data);
        },

        /**
         * 新增功能
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async addProductService({commit}, data) {
            return await app.$api.product.addProductService(data)
        },

        /**
         * 删除产品功能
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async deleteProductService({commit}, data) {
            return await app.$api.product.deleteProductService(data);
        },

        /**
         * 获取产品功能列表
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async queryProductServiceList({commit}, data) {
            return await app.$api.product.queryProductServiceList(data);
        },

        /**
         * 修改功能
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async modifyProductService({commit}, data) {
            return await app.$api.product.modifyProductService(data);
        },

        /**
         * 删除属性功能
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async deleteProductAttribute({commit}, data) {
            return await app.$api.product.deleteProductAttribute(data);
        },

        /**
         * 获取属性详情
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async getProductAttributeDetail({commit}, data) {
            return await app.$api.product.getProductAttributeDetail(data);
        },

        /**
         * 导出功能定义
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async exportFunction({commit}, data) {
            return await app.$api.product.exportFunction(data);
        },

        /**
         * 获取产品标准功能列表
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async getStandardServiceList({commit}, data) {
            return await app.$api.product.getStandardServiceList(data);
        },

        /**
         * 从标准功能新增功能
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async addFromStandard({commit}, data) {
            return await app.$api.product.addFromStandard(data);
        }

    }

}

export default effect;
