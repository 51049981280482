import app from "../../main";

const firmware = {

    namespaced: true,

    state: {

        //新增固件弹窗开关
        addFirmwareDialogVisible: false,

        //固件类型 - 1:含SDK固件; 2:MCU固件; 3:ota升级页面新增固件
        firmwareDialogType: 1,

        //模组列表弹窗开关
        moduleDialogVisible: false,

        //模组列表
        moduleList: [],

        //选中模组
        selectModuleId: ""

    },

    getters: {},

    mutations: {

        SET_ADD_FIRMWARE_DIALOG_VISIBLE: (state, addFirmwareDialogVisible) => {
            state.addFirmwareDialogVisible = addFirmwareDialogVisible;
        },

        SET_MODULE_DIALOG_VISIBLE: (state, moduleDialogVisible) => {
            state.moduleDialogVisible = moduleDialogVisible;
        },

        SET_MODULE_LIST: (state, moduleList) => {
            state.moduleList = moduleList;
        },

        SET_SELECT_MODULE_ID: (state, selectModuleId) => {
            state.selectModuleId = selectModuleId;
        },

        SET_FIRMWARE_DIALOG_TYPE: (state, firmwareDialogType) => {
            state.firmwareDialogType = firmwareDialogType;
        }

    },

    actions: {

        closeAddFirmwareDialog({commit}) {
            commit('SET_ADD_FIRMWARE_DIALOG_VISIBLE', false);
        },

        closeModuleDialog({commit}) {
            commit('SET_MODULE_DIALOG_VISIBLE', false);
        },

        /**
         * 查看烧录信息
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async getProductBurningInfo({commit}, data) {
            return await app.$api.product.getProductBurningInfo(data);
        },

        /**
         * 新建固件
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async addFirmware({commit}, data) {
            return await app.$api.product.addFirmware(data);
        },

        /**
         * 模组查询接口
         * @param commit
         * @returns {Promise<*>}
         */
        async getModuleListByCategoryCode({commit}, data) {
            await app.$api.product.getModuleListByCategoryCode(data).then(res => {
                commit('SET_MODULE_LIST', res.result)
            }, err => {
                console.log(err);
                app.$dialog.showMessage(err.resultMsg, app.$config.TOAST_ERROR);
            });
        },

        /**
         * 删除固件
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async delFirmware({commit}, data) {
            return await app.$api.product.delFirmware(data);
        },

        /**
         * 发布固件
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async pubFirmware({commit}, data) {
            return await app.$api.product.pubFirmware(data);
        },

        /**
         * 固件查询
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryFirmware({commit}, data) {
            return await app.$api.product.queryFirmware(data);
        },

        /**
         * 固件查询接口
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryFirmwareList({commit}, data) {
            return await app.$api.product.queryFirmwareList(data);
        },

        /**
         * 查看固件资源信息
         * @param commit
         * @returns {Promise<*>}
         */
        async getFirmwareResource({commit}, data) {
            return await app.$api.product.getFirmwareResource(data);
        }

    }

};

export default firmware;
