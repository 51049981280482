export default {
    message: {
        "protocolTypeList1": "WiFi-Bluetooth",
        "protocolTypeList2": "Bluetooth",
        "protocolTypeList3": "LTE Cat.1-Bluetooth",
        "protocolTypeList4": "LTE Cat.M-Bluetooth",
        "productStatusList1": "In Development",
        "productStatusList2": "Applying for Release",
        "productStatusList3": "Released",
        "certifiedEnterpriseStatusList1": "Under Certification",
        "certifiedEnterpriseStatusList2": "Certification Successful",
        "certifiedEnterpriseStatusList3": "Certification Failed",
        "upgradeTypeList1": "All",
        "upgradeTypeList2": "Firmware",
        "upgradeStateList1": "Upgrade Successful",
        "upgradeStateList2": "Upgrade Failed",
        "regionList2": "Guangdong",
        "regionList3": "Changsha",
        "regionList4": "Nanchang",
        "releaseModeList1": "Unreleased",
        "releaseModeList2": "Gray Release",
        "releaseModeList3": "Full Release",
        "releaseModeList4": "Designated Release",
        "formatList1": "Numeric",
        "formatList2": "Integer",
        "formatList3": "Decimal",
        "formatList4": "Enumeration",
        "formatList5": "Boolean",
        "formatList6": "Character",
        "formatList7": "Passthrough",
        "formatList8": "Fault",
        "formatList9": "Short Integer",
        "formatList10": "Long Integer",
        "authorityList1": "Write Only (wo)",
        "authorityList2": "Read Only (ro)",
        "authorityList3": "Read and Write (rw)",
        "effectTypeList1": "Standard Function",
        "effectTypeList2": "Custom Function",
        "empowerStatusList1": "Adding",
        "empowerStatusList2": "Importing",
        "empowerStatusList3": "Pending Review",
        "empowerStatusList4": "Application Successful",
        "empowerStatusList5": "Application Failed",
        "deviceStateList1": "Pending Activation",
        "deviceStateList2": "Activated",
        "deviceTypeList1": "Normal",
        "deviceTypeList2": "Virtual",
        "deviceTypeList3": "Preview",
        "deviceNetworkStateList2": "Online",
        "deviceNetworkStateList3": "Offline",
        "workOrderTypeList1": "Repair Work Order",
        "workOrderTypeList2": "Customer Service Inquiry",
        "workOrderStatesList1": "Pending",
        "workOrderStatesList2": "In Progress",
        "workOrderStatesList3": "Processed",
        "deliveryTypeList1": "Delivery",
        "deliveryTypeList2": "Report",
        "logTypeList1": "Communication Log",
        "logTypeList2": "Online/Offline Log",
        "logTypeList3": "Bind/Unbind Log",
        "logTypeList4": "Network Configuration Log",
        "empowerObjectList1": "Product",
        "empowerObjectList2": "Service",
        "empowerObjectiveList1": "Debugging",
        "empowerObjectiveList2": "Mass Production",
        "empowerObjectiveList3": "Authorization",
        "autoAuthList1": "Yes",
        "autoAuthList2": "No",
        "antennaTypeList1": "External Antenna",
        "antennaTypeList2": "Onboard Antenna",
        "antennaTypeList3": "Unknown",
        "activeTypeList1": "Unique ID Activation",
        "activeTypeList2": "License Activation",
        "activeTypeList3": "Automatic Activation"
    },
    index: {
        text: "APP Download",
        text1: "Openness and Co-creation",
        text2: "Langolink (also known as XiaoFeng LianLian) is dedicated to providing end-to-end intelligent and connected product solutions, including cloud platforms, intelligent modules, AI algorithm models, and mobile apps. These solutions are ready to use out of the box and also offer basic APIs for application development on various platforms, enabling customers to develop necessary personalized functions.",
        text3: "Cloud Platform",
        text4: "Separated design for middle platform and business platform with good scalability",
        text5: "Access and control of intelligent modules, centralized control, OTA firmware updates, and remote updates of algorithm models, providing comprehensive services for products",
        text6: "Mobile APP",
        text7: "Modular development with good scalability",
        text8: "Available on Android, iOS, and WeChat Mini Programs",
        text9: "Various Intelligent Hardware Modules",
        text10: "Native access to the cloud platform without integration development, allowing users to focus on personalized business development; comprehensive coverage of intelligent module functions, selectable as needed",
        text11: "High AI Computing SOC Module",
        text12: "Supports NPU ranging from 0.2T to 6T, 1 to 64 camera inputs, and 8K encoding/decoding;",
        text13: "Supports small-size LCD display and HDMI image output;",
        text14: "Supports multiple OS including Ubuntu, AOSP, OpenHarmony",
        text15: "High Business Computing SOC Module",
        text16: "Supports dual-core to 8-core ARM or MIPS CPU with frequencies up to 1.8Hz",
        text17: "Supports wired and wireless networking, capable of driving large-size LCD displays",
        text18: "Basic Connectivity WIFI SOC Module",
        text19: "Supports relevant network technologies:",
        text20: "WIFI4 + WIFI5 + Bluetooth",
        text21: "AI Algorithm Models",
        text22: "Combining software and hardware, multi-modal data fusion, faster, more accurate, and more efficient",
        text23: "Image",
        text24: "Supports object detection, recognition, tracking, etc.",
        text25: "Sound",
        text26: "Supports echo cancellation, sound source localization, noise suppression, etc.",
        text27: "Motion Control",
        text28: "Fast mapping, path planning, proactive obstacle avoidance, etc.",
        text29: "Cooperation Process",
        text30: "Become a Developer",
        text31: "Register a platform account and obtain development permissions",
        text32: "Create Product",
        text33: "Define product functions and technical solutions",
        text34: "Develop Product",
        text35: "Develop software and hardware functions and pass tests",
        text36: "Release Product",
        text37: "Obtain platform permissions to promote and sell",
        text38: "Scan the QR code to download the app"
    },
    login: {
        "text1": "Please select a data center",
        "text2": "Please select a language",
        "text3": "Login",
        "text4": "Register",
        "text5": "Please select a country/region",
        "text6": "Phone number",
        "text7": "Email",
        "text8": "Please enter",
        "text9": "Verification code",
        "text10": "Password",
        "text11": "Forgot password?",
        "text12": "Password login",
        "text13": "I have read and agree to the",
        "text14": "User Agreement",
        "text15": "Privacy Policy",
        "text16": "and",
        "text17": "Please enter email or phone number",
        "text18": "Please enter a valid email or phone number",
        "text19": "Please enter phone number",
        "text20": "Please enter a valid phone number",
        "text21": "Please enter email",
        "text22": "Please enter a valid email",
        "text23": "Get verification code",
        "text24": "Please enter password",
        "text25": "Please enter verification code",
        "text26": "Please check the agreement first",
        "text27": "Re-acquire after",
        "text28": "Resend verification code",
        "text29": "Confirm password",
        "text30": "Please enter the password again",
        "text31": "The two passwords do not match!",
        "text32": "Must include upper/lowercase, numbers, special chars, 8+ characters.",
        "text33": "Registration successful!",
        "text34": "Verification code login",
        "text35": "Reset Password",
        "text36": "Reset Successful"
    },
    main: {
        "text1": "Personal Center",
        "text2": "Log Out",
        "text3": "Documentation",
        "text4": "Console",
        "text5": "Switched Successfully!",
        "text6": "Do you want to log out?",
        "text7": "Prompt",
        "text8": "Log Out",
        "text9": "Cancel",
        "text10": "Please select a language",
        "text11": "Personal Space"
    },
    mainIndex: {
        "text1": "Product Development",
        "text2": "Product Name/PID",
        "text3": "All Categories",
        "text4": "All Communication Methods",
        "text5": "All Statuses",
        "text6": "No Product Mockup",
        "text7": "Edit",
        "text8": "Copy",
        "text9": "Delete",
        "text10": "Device Management",
        "text11": "OTA Upgrade",
        "text12": "Do you want to delete this product?",
        "text13": "Prompt",
        "text14": "Delete",
        "text15": "Cancel",
        "text16": "Deleted Successfully",
        "text17": "Copy Product",
        "text18": "Product Name:",
        "text19": "Please enter product name",
        "text20": "Product Model:",
        "text21": "Please enter product model, only lowercase letters or numbers are supported",
        "text22": "Cancel",
        "text23": "Confirm",
        "text24": "Input cannot exceed 20 characters",
        "text25": "Only lowercase letters, underscores, and numbers are allowed",
        "text26": "Copy successful"
    },
    addProduct: {
        "text1": "Create Product",
        "text2": "Category Name",
        "text3": "No relevant category found",
        "text4": "Product Name:",
        "text5": "Within 20 characters",
        "text6": "Product Model:",
        "text7": "Please enter the product model, only uppercase and lowercase letters, underscores, and numbers are supported",
        "text8": "Communication Method:",
        "text9": "Select Communication Method",
        "text10": "Network Configuration Method:",
        "text11": "Product Mockup:",
        "text12": "168*168 pixels, PNG format, transparent background, within 100KB",
        "text13": "Remarks:",
        "text14": "Please enter remarks",
        "text15": "Create New Product",
        "text16": "Select Category",
        "text17": "Select Development Scheme",
        "text18": "Fill in Basic Information",
        "text19": "Create",
        "text20": "Non-Embedded System Access",
        "text21": "Applicable for devices with systems like Android, Linux, etc.",
        "text22": "Embedded System Access",
        "text23": "Applicable for embedded devices with systems like RTOS, etc.",
        "text24": "Cloud-to-Cloud Access",
        "text25": "Applicable for devices with existing cloud platforms",
        "text26": "Please enter product name",
        "text27": "Input cannot exceed 20 characters",
        "text28": "Please select a product category",
        "text29": "Please select a communication method",
        "text30": "Only uppercase and lowercase letters, underscores, and numbers are allowed",
        "text31": "Please select a network configuration method",
        "text32": "Product mockup only supports PNG format!",
        "text33": "Uploaded image size cannot exceed 100KB!",
        "text34": "Uploaded image dimensions do not match, must be 168*168!",
        "text35": "Please select a category",
        "text36": "Please select a development scheme",
        "text37": "Added successfully"
    },
    deviceManagement: {
        "text1": "Device Management",
        "text2": "Device Overview",
        "text3": "Total Activated Devices (Units)",
        "text4": "Online Devices",
        "text5": "Offline Devices",
        "text6": "Device List",
        "text7": "Please enter",
        "text8": "Phone Number",
        "text9": "Device ID",
        "text10": "Please select",
        "text11": "to",
        "text12": "Start Date",
        "text13": "End Date",
        "text14": "Type",
        "text15": "Status",
        "text16": "Online",
        "text17": "Offline",
        "text18": "Bound User",
        "text19": "Activation Date",
        "text20": "Activation Status",
        "text21": "Online Status",
        "text22": "Actions",
        "text23": "Logs"
    },
    product: {
        "text1": "Product Edit",
        "text2": "No Product Image",
        "text3": "Product Model:",
        "text4": "Category:",
        "text5": "Communication Method:",
        "text6": "Remarks:",
        "text7": "View Details",
        "text8": "Function Definition",
        "text9": "Device Access",
        "text10": "Application Development",
        "text11": "Advanced Configuration",
        "text12": "Product Release",
        "text13": "Copy Successful",
        "text14": "Basic Configuration",
        "text15": "Product Name:",
        "text16": "Please enter the product name",
        "text17": "Only lowercase letters, underscores, and numbers are allowed",
        "text18": "Please select a communication method",
        "text19": "Network Configuration Method:",
        "text20": "Product Image:",
        "text21": "168*168 pixels, PNG format, transparent background, within 100KB",
        "text22": "Save",
        "text23": "Input cannot exceed 20 characters",
        "text24": "Only uppercase/lowercase letters, underscores, and numbers are allowed",
        "text25": "Modification Successful",
        "text26": "Product images can only be in PNG format!",
        "text27": "Uploaded image size cannot exceed 100KB!",
        "text28": "Uploaded image dimensions do not match, must be 168*168!",
        "text29": "Product Information",
        "text30": "Product Status:",
        "text31": "Product Category:",
        "text32": "Standard Function",
        "text33": "Export All Functions",
        "text34": "Function Name",
        "text35": "Function Identifier",
        "text36": "Attribute Name",
        "text37": "Attribute Identifier",
        "text38": "Data Type",
        "text39": "Attribute Remarks",
        "text40": "Permissions",
        "text41": "Function Remarks",
        "text42": "Attribute Actions",
        "text43": "Edit",
        "text44": "Details",
        "text45": "Delete",
        "text46": "Custom Function",
        "text47": "Add Attribute",
        "text48": "Function Configuration",
        "text49": "Configure related services according to your needs. If not needed, please click close.",
        "text50": "No Function Configuration Items",
        "text51": "Setting Successful",
        "text52": "(Required)",
        "text53": "Do you want to delete this attribute?",
        "text54": "Prompt",
        "text55": "Cancel",
        "text56": "Deletion Successful",
        "text57": "Do you want to delete this function?",
        "text58": "Add Function",
        "text59": "Please enter the function name",
        "text60": "Please enter the function identifier",
        "text61": "Please enter remarks",
        "text62": "Function Attributes",
        "text63": "Cancel",
        "text64": "Submitting",
        "text65": "Confirm",
        "text66": "Input cannot exceed 25 characters",
        "text67": "Please select permissions",
        "text68": "Input cannot exceed 32 characters",
        "text69": "Only letters, underscores, and numbers are allowed",
        "text70": "Addition Successful",
        "text71": "Please enter the attribute name",
        "text72": "Please enter the attribute identifier",
        "text73": "Format",
        "text74": "Type Length",
        "text75": "Please select the type length",
        "text76": "Value Range",
        "text77": "Multiplier",
        "text78": "The data will be divided by a power of 10, suitable for decimals. For example, if the reported value is 1 and the decimal factor is set to 1, the converted data will be 0.1",
        "text79": "Please select a multiplier",
        "text80": "Step",
        "text81": "Please enter the attribute step",
        "text82": "Unit",
        "text83": "Please enter the attribute unit",
        "text84": "Enum Value:",
        "text85": "Fault Value:",
        "text86": "Enum Value Key",
        "text87": "Fault Value Key",
        "text88": "Enum Value Content",
        "text89": "Fault Value Content",
        "text90": "Maximum Value:",
        "text91": "Maximum length in bytes",
        "text92": "Please enter attribute remarks",
        "text93": "Please select a format",
        "text94": "Please enter a step",
        "text95": "Please enter a unit",
        "text96": "Please complete the list",
        "text97": "Step cannot be 0",
        "text98": "Start value cannot be greater than or equal to end value",
        "text99": "Please enter a value range",
        "text100": "Please enter the maximum string",
        "text101": "Add Attribute",
        "text102": "Edit Attribute",
        "text103": "Attribute Details",
        "text104": "Name",
        "text105": "Confirm",
        "text106": "Operation Successful",
        "text107": "Edit Function",
        "text108": "Function Details",
        "text109": "Select Standard Function",
        "text110": "Identifier",
        "text111": "You have selected all available functions",
        "text112": "Selected Standard Function",
        "text113": "No functions selected this time",
        "text114": "Please select the standard functions to add!",
        "text115": "Download",
        "text116": "Go to Authorization",
        "text117": "Go to Debugging",
        "text118": "Firmware Version List",
        "text119": "Create New Version",
        "text120": "Firmware Version",
        "text121": "Update Log",
        "text122": "Upload Time",
        "text123": "Status",
        "text124": "Unreleased",
        "text125": "Released",
        "text126": "Actions",
        "text127": "Release",
        "text128": "Please select the appropriate access protocol",
        "text129": "Burn Authentication Credentials",
        "text130": "Device Debugging (Please confirm whether development is complete before debugging)",
        "text131": "Upload Firmware with SDK",
        "text132": "Do you want to release the current firmware?",
        "text133": "Confirm",
        "text134": "Release Successful",
        "text135": "Do you want to delete the current firmware?",
        "text136": "Upload SDK Firmware Package",
        "text137": "Upload MCU Firmware",
        "text138": "Add Firmware",
        "text139": "Format only supports letters, numbers, and periods",
        "text140": "Firmware Package",
        "text141": "Click to Upload",
        "text142": "Please enter the update log",
        "text143": "Please enter the firmware version number",
        "text144": "Only letters, numbers, and periods are allowed",
        "text145": "Please enter the update log",
        "text146": "Confirm to remove",
        "text147": "Please upload the firmware",
        "text148": "Upload Successful",
        "text149": "Select Module",
        "text150": "No Module Image",
        "text151": "Model",
        "text152": "Chip",
        "text153": "Antenna",
        "text154": "Reselect",
        "text155": "Serial Communication Protocol",
        "text156": "MCU Firmware Version List",
        "text157": "Please select the module to connect to the cloud",
        "text158": "Please download the development materials",
        "text159": "Device Debugging (Please confirm whether development is complete before debugging)",
        "text160": "Upload Firmware",
        "text161": "Module List",
        "text162": "Please select the access method",
        "text163": "Offline Self-developed",
        "text164": "Online Generated",
        "text165": "Application SDK",
        "text166": "Version List",
        "text167": "Version Number",
        "text168": "Preview",
        "text169": "Please download the application development materials",
        "text170": "Application Development Debugging",
        "text171": "Upload RN Application Package (Please confirm debugging is complete before uploading)",
        "text172": "Do you want to release the current application?",
        "text173": "Do you want to delete the current application?",
        "text174": "Upload RN Application Package",
        "text175": "RN Application Package",
        "text176": "Android Package:",
        "text177": "iOS Package:",
        "text178": "HD Package:",
        "text179": "Input version number does not match the uploaded RN application package version number",
        "text180": "Please enter the RN application version number",
        "text181": "RN package name is incorrect",
        "text182": "The currently uploaded RN package does not belong to the",
        "text183": "platform",
        "text184": "The version number of the uploaded Android package does not match the iOS package",
        "text185": "The version number of the uploaded iOS package does not match the Android package",
        "text186": "The PID of the currently uploaded",
        "text187": "The package's PID does not belong to this product",
        "text188": "Please upload the iOS application",
        "text189": "Please upload the Android application",
        "text190": "Please upload the HD application",
        "text191": "Verification error, please check if the uploaded RN package version number and PID are correct",
        "text192": "Settings",
        "text193": "Network Configuration Guide",
        "text194": "Configure device network instructions and related guide diagrams for network initialization",
        "text195": "Steps",
        "text196": "Add Step",
        "text197": "Guide Title",
        "text198": "Please enter the guide title",
        "text199": "Guide Description",
        "text200": "Guide Image",
        "text201": "Formats: png, jpeg, jpg, gif, bmp",
        "text202": "No image preview available",
        "text203": "Next Step",
        "text204": "Please enter the guide description",
        "text205": "Input cannot exceed 40 characters",
        "text206": ", please upload the guide image!",
        "text207": "Submission Successful!",
        "text208": "Images are only supported in jpg, jpeg, png, gif, bmp formats!",
        "text209": "Image size cannot exceed 10MB!",
        "text210": "Uploaded image dimensions do not match, must be 320*320!",
        "text211": "Product Category:",
        "text212": "Please enter the model",
        "text213": "Please select the firmware",
        "text214": "Please select the panel",
        "text215": "Confirm Online",
        "text216": "Please confirm the basic information",
        "text217": "Please confirm the firmware version",
        "text218": "Please confirm the RN application version",
        "text219": "Please enter the product model",
        "text220": "Uploaded image dimensions do not match, must be 168*168!",
        "text221": "Please upload the product image",
        "text222": "Functional Actions",
    },
    deviceLog: {
        "text1": "Device Logs",
        "text2": "Communication Logs",
        "text3": "Online/Offline Logs",
        "text4": "Binding/Unbinding Logs",
        "text5": "Device ID",
        "text6": "To",
        "text7": "Start Date",
        "text8": "End Date",
        "text9": "Please Select",
        "text10": "Number",
        "text11": "Time",
        "text12": "Delivery Type",
        "text13": "Log Details"
    },
    debug: {
        "text1": "Device Debugging",
        "text2": "How to Debug",
        "text3": "No Product Mockup",
        "text4": "Switch Product",
        "text5": "Category:",
        "text6": "No Debuggable Products",
        "text7": "Real Device List",
        "text8": "Please enable debug mode in the app before adding",
        "text9": "Device ID",
        "text10": "Bound User",
        "text11": "Status",
        "text12": "Offline",
        "text13": "Online",
        "text14": "Device Debugging",
        "text15": "Action",
        "text16": "Delete",
        "text17": "No Debuggable Devices",
        "text18": "Virtual Device List",
        "text19": "Add Virtual Device",
        "text20": "App Debugging",
        "text21": "1. If the app is not downloaded, scan the QR code below to download the app",
        "text22": "2. If the app is downloaded, scan the QR code below to bind the device",
        "text23": "Do you want to delete the current virtual device?",
        "text24": "Prompt",
        "text25": "Confirm",
        "text26": "Cancel",
        "text27": "Deleted Successfully",
        "text28": "Do you want to delete the current real device?",
        "text29": "Virtual Device ID:",
        "text30": "Function Debugging",
        "text31": "Function Name",
        "text32": "Function Name",
        "text33": "Parameter",
        "text34": "Please Select",
        "text35": "Content",
        "text36": "MQTT Connection Failed!",
        "text37": "Report Failed!",
        "text38": "Report Successful!",
        "text39": "Please Fill",
        "text40": "Report",
        "text41": "Switch Device",
        "text42": "Device Status",
        "text43": "Get All Properties",
        "text44": "Property Name",
        "text45": "Property Value",
        "text46": "Get",
        "text47": "Set",
        "text48": "Content",
        "text49": "Device Has Not Reported Data!",
        "text50": "Successfully Retrieved!",
        "text51": "Sent Successfully",
        "text52": "Status Code:"
    },
    empower: {
        "text1": "Authorization Service",
        "text2": "Authorization Certificate Overview",
        "text3": "Apply for Certificate",
        "text4": "Total Authorization Applications",
        "text5": "Product Authorization Applications",
        "text6": "Service Authorization Applications",
        "text7": "Total Activations",
        "text8": "Authorization List",
        "text9": "Need Help?",
        "text10": "Product Name/Service Name/PID/SID",
        "text11": "To",
        "text12": "Start Date",
        "text13": "End Date",
        "text14": "Authorization Target",
        "text15": "Authorization Purpose",
        "text16": "Number",
        "text17": "Authorization Target",
        "text18": "Authorized Product/Service",
        "text19": "Authorization Purpose",
        "text20": "Activation Method",
        "text21": "Total Authorizations Available",
        "text22": "Allowed Quantity After Approval",
        "text23": "Remaining Activations",
        "text24": "Refers to the remaining available authorizations",
        "text25": "Trial Quantity",
        "text26": "Application Date",
        "text27": "Application Status",
        "text28": "Action",
        "text29": "Details",
        "text30": "Device Certificate",
        "text31": "Deactivate",
        "text32": "Activate",
        "text33": "Re-authenticate",
        "text34": "Copied Successfully",
        "text35": "Authorization Certificate",
        "text36": "Confirm",
        "text37": "Do you want to deactivate?",
        "text38": "Do you want to activate?",
        "text39": "Prompt",
        "text40": "Cancel",
        "text41": "Deactivated Successfully",
        "text42": "Activated Successfully",
        "text43": "Do you want to disable auto-counting?",
        "text44": "Do you want to enable auto-counting?",
        "text45": "Disable",
        "text46": "Enable",
        "text47": "Disabled Successfully",
        "text48": "Enabled Successfully",
        "text50": "Add Authorization",
        "text51": "Please Enter Application Quantity",
        "text52": "Cancel",
        "text53": "Confirm",
        "text54": "Only Positive Integers Allowed",
        "text55": "Application Submitted Successfully",
        "text56": "Apply for Authorization Certificate",
        "text57": "Please Select Authorization Target",
        "text58": "For non-platform products, please select service retrieval",
        "text59": "Authorized Product",
        "text60": "Please Enter Product Name to Search",
        "text61": "Please Select Service (Multiple Selection)",
        "text62": "Authorization Purpose",
        "text63": "Import Device Unique Identifiers",
        "text64": "Download Template",
        "text65": "Click or Drag File Here to Upload",
        "text66": "Supported File Formats:",
        "text67": "Application Quantity",
        "text68": "Refers to the total number of authorizations applied for (including both reviewed and unreviewed)",
        "text69": "Please Enter",
        "text70": "Is Trial Supported? (Days)",
        "text71": "Please Enter Trial Duration",
        "text72": "Apply",
        "text73": "Please Select Product",
        "text74": "Please Select Service",
        "text75": "Please Select Authorization Purpose",
        "text76": "The Maximum Application Quantity is 50,000",
        "text77": "Please Enter the License Application Quantity",
        "text78": "For Debugging Purposes, the Maximum Application Quantity is 300",
        "text79": "Please Upload Device Unique Identifiers",
        "text80": "There are Empty Identifiers, Please Check Carefully",
        "text81": "Application Sent Successfully",
        "text82": "No Data in Excel File",
        "text83": "Add Activation",
        "text84": "Please Enter Device Unique Identifier",
        "text85": "Upload File",
        "text86": "Confirm",
        "text87": "Please Fill in Unique Identifier or Upload Excel File",
        "text88": "Added Successfully",
        "text89": "Authorization Service Details",
        "text90": "Authorization Number",
        "text91": "Application Date",
        "text92": "Supports Trial",
        "text93": "Days",
        "text94": "Total Applications",
        "text95": "Activated Quantity",
        "text96": "Remaining Quantity",
        "text97": "Pending Review",
        "text98": "Approved",
        "text99": "Rejected",
        "text100": "Activation List",
        "text101": "Pending Review",
        "text102": "Review Not Approved",
        "text103": "License Search",
        "text104": "Device Unique Identifier Search",
        "text105": "All",
        "text106": "Export",
        "text107": "Device Unique Identifier",
        "text108": "Activation Status",
        "text109": "Activation Date",
        "text110": "Filter Conditions:",
        "text111": "Please Select",
        "text112": "Export Quantity:",
        "text113": "Please Enter Export Quantity",
        "text114": "Prioritize Exporting Unexported Licenses",
        "text115": "Please Select Filter Conditions",
        "text116": "Confirm Export of Current Activation List?",
        "text117": "Confirm",
        "text118": "License Export Successful"
    },
    dashboard: {
        "text1": "Factory Test Data",
        "text2": "No Product Mockup Image",
        "text3": "Switch Product",
        "text4": "Category:",
        "text5": "Data Updated Daily at 00:00",
        "text6": "Please search for the work order number",
        "text7": "Please Select",
        "text8": "Work Order Number",
        "text9": "Address",
        "text10": "Number",
        "text11": "Total Tests",
        "text12": "Total Passes",
        "text13": "Total Failures",
        "text14": "Overall Failure Rate",
        "text15": "Test Time",
        "text16": "Actions",
        "text17": "Details",
        "text18": "Edit Address",
        "text19": "Please Enter Address",
        "text20": "Cancel",
        "text21": "Modify",
        "text22": "Modification Successful",
        "text23": "Distribution of Abnormal Function Items",
        "text24": "Export",
        "text25": "Abnormal Ratio",
        "text26": "Online Statistics",
        "text27": "Device Online Statistics",
        "text28": "Only data from the last 24 hours is counted. Devices without data are not displayed.",
        "text29": "Product Category",
        "text30": "All Categories",
        "text31": "Please Select Date",
        "text32": "Please Enter Device ID",
        "text33": "Device ID",
        "text34": "Total Count (Unit: Times)",
        "text35": "Risk Status",
        "text36": "Displays 'Abnormal' if it exceeds the threshold, otherwise it's normal and not shown in the table. The default threshold is 50 times/day.",
        "text37": "Abnormal",
        "text38": "Normal",
        "text39": "Start time",
        "text40": "End time",
        "text41": "Please search for address",
    },
    notice: {
        "text1": "Mark as Read",
        "text2": "Type",
        "text3": "Announcer",
        "text4": "Title",
        "text5": "Date",
        "text6": "Status",
        "text7": "Read",
        "text8": "Unread",
        "text9": "All Announcements",
        "text10": "Update",
        "text11": "Message",
        "text12": "Please Select Messages to Mark"
    },
    userInfo: {
        "text1": "Personal Information",
        "text2": "Upload Avatar",
        "text3": "You can drag the image to the avatar area on the left to complete the upload",
        "text4": "Nickname",
        "text5": "Please enter a nickname",
        "text6": "Edit Nickname",
        "text7": "Confirm",
        "text8": "Cancel",
        "text9": "User ID",
        "text10": "Country/Region",
        "text11": "Address",
        "text12": "Please enter an address",
        "text13": "Edit Address",
        "text14": "Password",
        "text15": "Change Password",
        "text16": "Phone Number",
        "text17": "Not Bound",
        "text18": "Change Phone Number",
        "text19": "Bind Phone Number",
        "text20": "Email",
        "text21": "Change Email",
        "text22": "Bind Email",
        "text23": "Please enter a nickname!",
        "text24": "Please enter an address!",
        "text25": "Please upload an avatar!",
        "text26": "Update Successful",
        "text27": "Uploaded avatar dimensions are incorrect, must be 200*200!",
        "text28": "Images only support jpg/jpeg/png/gif/bmp formats!",
        "text29": "Bind Phone Number",
        "text30": "Bind Email",
        "text31": "Please select a country/region",
        "text32": "Please enter a phone number",
        "text33": "Please enter an email address",
        "text34": "Verification Code",
        "text35": "Cancel",
        "text36": "Confirm",
        "text37": "Get Verification Code",
        "text38": "Please select a country/region",
        "text39": "Please enter the verification code",
        "text40": "Invalid phone number format",
        "text41": "Invalid email format",
        "text42": "s before you can retrieve again",
        "text43": "Retrieve Verification Code Again",
        "text44": "Please get the verification code first",
        "text45": "Binding Successful",
        "text46": "Change Phone Number",
        "text47": "Change Email",
        "text48": "To confirm that it's you, please enter your password to verify your identity:",
        "text49": "Please enter a new phone number",
        "text50": "Please enter a new email address",
        "text51": "Next",
        "text52": "Please enter your password",
        "text53": "Incorrect password",
        "text54": "Please enter a phone number",
        "text55": "Change Password",
        "text56": "Verify Account:",
        "text57": "New Password (up to 20 characters)",
        "text58": "Re-enter New Password (up to 20 characters)",
        "text59": "Please re-enter the password",
        "text60": "Passwords do not match!",
        "text61": "Please enter your old password",
        "text62": "Please enter a new password",
        "text63": "Must include uppercase and lowercase letters, numbers, special characters, and be at least 8 characters long",
        "text64": "Password successfully changed, please log in again"
    },
    enterpriseInfo: {
        "text1": "Enterprise Management",
        "text2": "Enterprise Certification",
        "text3": "Enterprise Name",
        "text4": "Enterprise Abbreviation",
        "text5": "English Abbreviation",
        "text6": "Legal Representative",
        "text7": "Contact Person",
        "text8": "Contact Information",
        "text9": "Application Status",
        "text10": "Actions",
        "text11": "Re-certification",
        "text12": "Owner",
        "text13": "Developer",
        "text14": "Product Personnel",
        "text15": "Apply for Certification",
        "text16": "Full Enterprise Name:",
        "text17": "Please enter the full enterprise name",
        "text18": "Enterprise Abbreviation:",
        "text19": "Please enter the enterprise abbreviation",
        "text20": "English Abbreviation:",
        "text21": "Please enter the English abbreviation",
        "text22": "Legal Representative:",
        "text23": "Please enter the legal representative's name",
        "text24": "Legal Representative Holding ID:",
        "text25": "File size cannot exceed 10MB, formats: png, jpeg, jpg, gif, bmp",
        "text26": "Enterprise Address:",
        "text27": "Please enter the enterprise address",
        "text28": "Business License:",
        "text29": "Enterprise Profile:",
        "text30": "Contact Person:",
        "text31": "Please enter the contact person",
        "text32": "Contact Phone:",
        "text33": "Please enter the contact phone",
        "text34": "Modify Application",
        "text35": "Register Application",
        "text36": "Fill in Enterprise Information",
        "text37": "Fill in Contact Person (Please keep the phone line open, otherwise it may delay the review time)",
        "text38": "Submit Application",
        "text39": "Input cannot exceed 20 characters",
        "text40": "Only English letters up to 10 characters are allowed",
        "text41": "Input cannot exceed 10 characters",
        "text42": "Please enter the contact phone number",
        "text43": "Please upload a photo of the legal representative holding the ID card",
        "text44": "Please upload the business license",
        "text45": "Modification Successful!",
        "text46": "Registration Successful!",
        "text47": "Images only support jpg/jpeg/png/gif/bmp formats!",
        "text48": "Uploaded image size cannot exceed 10MB!"
    },
    member: {
        "text1": "Member Management",
        "text2": "Nickname",
        "text3": "Add Member",
        "text4": "Member ID",
        "text5": "Nickname",
        "text6": "Member Permissions",
        "text7": "Available Products",
        "text8": "All Products",
        "text9": "Actions",
        "text10": "Edit",
        "text11": "Delete",
        "text12": "Transfer",
        "text13": "Do you want to remove this member from the enterprise?",
        "text14": "Notice",
        "text15": "Confirm",
        "text16": "Cancel",
        "text17": "Deletion Successful",
        "text18": "Do you want to transfer the enterprise to this user? (After the transfer, this account will leave the enterprise)",
        "text19": "Transfer Successful",
        "text20": "Add Member",
        "text21": "Edit Member",
        "text22": "Country/Region:",
        "text23": "Please select a country/region",
        "text24": "User ID:",
        "text25": "Enter the phone number and click search user",
        "text26": "Nickname:",
        "text27": "Enter a nickname",
        "text28": "Role:",
        "text29": "Available Products:",
        "text30": "All Products",
        "text31": "Specified Products",
        "text32": "Select Products",
        "text33": "Cancel",
        "text34": "Confirm",
        "text35": "Please enter phone number/email to search",
        "text36": "Please select a role",
        "text37": "Please select a product",
        "text38": "Added Successfully",
        "text39": "Request Error",
        "text40": "Modification Successful",
        "text41": "Please select",
        "text42": "Product Name/PID",
        "text43": "All Communication Methods",
        "text44": "All Statuses",
        "text45": "Product Image",
        "text46": "Product Name",
        "text47": "Product Type",
        "text48": "Communication Method",
        "text49": "Creation Time",
        "text50": "Remarks",
        "text51": "Development Status"
    },
    ota: {
        "text1": "OTA Upgrade",
        "text2": "Internal Product",
        "text3": "External Product",
        "text4": "Please enter the product name to search and switch",
        "text5": "No data available",
        "text6": "Release Date Range",
        "text7": "Please select firmware/APP identifier",
        "text8": "Please enter version number",
        "text9": "Add OTA",
        "text10": "Version Number",
        "text11": "Trigger Method",
        "text12": "Firmware Type",
        "text13": "Update Description",
        "text14": "Release Date",
        "text15": "Status",
        "text16": "Not Published",
        "text17": "Designated Release",
        "text18": "Gray Release",
        "text19": "Full Release",
        "text20": "Scheduled Release Date",
        "text21": "Action",
        "text22": "Download Firmware",
        "text23": "Release",
        "text24": "Details",
        "text25": "Edit",
        "text26": "Unpublish",
        "text27": "Delete",
        "text28": "Device Initiated (Self-check Upgrade)",
        "text29": "Server Triggered Upgrade",
        "text30": "Mobile App (App Triggered Upgrade)",
        "text31": "Check for Upgrade",
        "text32": "Once unpublished, the terminal will no longer be able to upgrade this version. It can be downloaded after re-release. Please confirm whether to unpublish carefully.",
        "text33": "Friendly Reminder",
        "text34": "Acknowledged",
        "text35": "Cancel",
        "text36": "Unpublish Successful",
        "text37": "Deletion cannot be undone. Do you want to continue deleting?",
        "text38": "Friendly Reminder",
        "text39": "Delete",
        "text40": "Delete Successful",
        "text41": "Basic Settings",
        "text42": "Product Name/Nickname",
        "text43": "Product Name: The name of the product created on the IoT platform. You can select the product name.",
        "text44": "Product Nickname: If the product is not created on the platform but needs to use the OTA function, you can enter/select a product nickname.",
        "text45": "Please select product name/nickname",
        "text46": "Firmware/APP Identifier",
        "text47": "Consistency verification of firmware identifiers is a key condition to ensure that the upgrade target is within the OTA upgrade scope.",
        "text48": "Enter the selected firmware/APP identifier",
        "text49": "The upgrade package supports only .zip format and must not exceed 8GB.",
        "text50": "Note: When uploading the upgrade package, ensure it contains necessary information such as version number. For details, see",
        "text51": "Upgrade Package Example",
        "text52": "Device-side Upgrade Settings",
        "text53": "Upgrade Package",
        "text54": "Please refer to the example to package the upgrade package, otherwise it may lead to upgrade failure.",
        "text55": "Trigger Method",
        "text56": "1. Self-check Upgrade: Automatically detects and upgrades at a set time point (e.g., 3 AM daily) if conditions are met.",
        "text57": "2. Check for Upgrade: Users trigger device detection via the device's human-computer interaction interface (e.g., touchscreen), and it upgrades immediately if conditions are met.",
        "text58": "3. App Triggered Upgrade: Users operate in the Xiao Feng Lian Lian app, and the device triggers detection immediately once online if conditions are met.",
        "text59": "4. Server Triggered: After new firmware release, the server pushes an update command to the device, and the device will automatically upgrade once online.",
        "text60": "Please select a trigger method",
        "text61": "Please select a download method",
        "text62": "Please select an installation method",
        "text63": "Download Method",
        "text64": "Foreground Download: Firmware download visible to users.",
        "text65": "Silent Download: Firmware download not visible to users.",
        "text66": "Installation Method",
        "text67": "Mandatory Installation: Must be installed, no option to cancel the download and installation.",
        "text68": "Non-mandatory Installation: Users can cancel the download and installation.",
        "text69": "Install and Restart",
        "text70": "Erase Partition",
        "text71": "Add Partition",
        "text72": "Update Description",
        "text73": "Please enter update description",
        "text74": "Add",
        "text75": "Modify",
        "text76": "Product Name",
        "text77": "Product Nickname",
        "text78": "Please enter firmware/APP identifier",
        "text79": "Please select whether to install and restart",
        "text80": "Foreground Download",
        "text81": "Silent Download",
        "text82": "Yes",
        "text83": "No",
        "text84": "Data Partition",
        "text85": "Media Partition",
        "text86": "Product name/nickname not found. Would you like to create a new product nickname?",
        "text87": "Friendly Reminder",
        "text88": "Please enter partition name",
        "text89": "Confirm",
        "text90": "Partition name must be greater than 0 and less than or equal to 20 characters",
        "text91": "Addition Successful",
        "text92": "File size must not exceed 8GB",
        "text93": "Please upload upgrade package",
        "text94": "Addition Successful",
        "text95": "Modification Successful",
        "text96": "Chip Firmware",
        "text97": "HarmonyOS",
        "text98": "WIFI Module Firmware",
        "text99": "Mandatory Installation",
        "text100": "Non-mandatory Installation",
        "text101": "Start Download",
        "text102": "Download Complete",
        "text103": "Start Upgrade",
        "text104": "Upgrade Complete",
        "text105": "Modify OTA"
    },
    releaseMsg: {
        "text1": "Release",
        "text2": "Firmware Identifier:",
        "text3": "Version Number",
        "text4": "Product ID",
        "text5": "Creation Time",
        "text6": "Firmware Type",
        "text7": "Download Link",
        "text8": "Copy",
        "text9": "Edit",
        "text10": "Cancel",
        "text11": "Confirm",
        "text12": "Please enter content",
        "text13": "Device-side Upgrade Settings",
        "text14": "Installation Method",
        "text15": "Trigger Method",
        "text16": "Install and Restart",
        "text17": "Update Description",
        "text18": "Total Number of Devices",
        "text19": "Number of Upgraded Devices",
        "text20": "Designated Release",
        "text21": "Gray Release",
        "text22": "Full Release",
        "text23": "Once unpublished, the terminal will no longer be able to upgrade this version. It can be downloaded after re-release. Please confirm whether to unpublish carefully.",
        "text24": "Friendly Reminder",
        "text25": "Acknowledged",
        "text26": "Cancel",
        "text27": "Unpublish Successful",
        "text28": "Please select a release type",
        "text29": "The quantity for gray release cannot be 0",
        "text30": "Please fill in the unique identifier of the devices to be released first",
        "text31": "Please select the scheduled release time first",
        "text32": "The scheduled release time must be set at least 10 minutes after the current time",
        "text33": "Release Successful",
        "text34": "Modification Successful",
        "text39": "Designated Release Settings",
        "text40": "Please enter the device unique identifier.",
        "text41": "Format Description:",
        "text42": "Separate multiple devices with a comma (,) like: AA:62:94:09:11:10,AA:62:94:09:11:12",
        "text43": "For a continuous range use 'start value~end value', like: AA:62:94:09:11:14~AA:62:94:09:11:20",
        "text44": "Local Upload",
        "text45": "Before Uploading",
        "text46": "Download Template",
        "text47": ", format according to template requirements",
        "text48": "Gray Release Settings",
        "text49": "Release Time",
        "text50": "Immediate Release",
        "text51": "Scheduled Release",
        "text52": "Select Release Time",
        "text53": "Release",
        "text54": "Unpublish",
        "text55": "Edit",
        "text56": "Save",
        "text57": "Full Release"
    },
    firmwareMsg: {
        "text1": "Details",
        "text2": "Firmware Identifier:",
        "text3": "Version Number",
        "text4": "Product ID",
        "text5": "Creation Time",
        "text6": "Firmware Type",
        "text7": "Download Link",
        "text8": "Copy",
        "text9": "Edit",
        "text10": "Cancel",
        "text11": "Confirm",
        "text12": "Please enter content",
        "text13": "Device-side Upgrade Settings",
        "text14": "Installation Method",
        "text15": "Trigger Method",
        "text16": "Install and Restart",
        "text17": "Yes",
        "text18": "No",
        "text19": "Release Settings",
        "text20": "Release Method",
        "text21": "Release Time",
        "text22": "Release Scope",
        "text23": "Upgrade Data",
        "text24": "Please search for device unique identifier",
        "text25": "Total Number of Devices",
        "text26": "Upgrade Successful",
        "text27": "Upgrade Failed",
        "text28": "Upgrading",
        "text29": "Proportion:",
        "text30": "Not Upgraded",
        "text31": "Device Unique Identifier",
        "text32": "Device Status",
        "text33": "Offline",
        "text34": "Online",
        "text35": "Current Version",
        "text36": "Upgrade Status",
        "text37": "Upgrade Time",
        "text38": "Modification Successful"
    },
    "role": {
        "text1": "Role Management",
        "text2": "Add Role",
        "text3": "Role Name",
        "text4": "Role Description",
        "text5": "Actions",
        "text6": "View",
        "text7": "Edit",
        "text8": "Authorize",
        "text9": "Delete",
        "text10": "Add Sub-Role",
        "text11": "Deleting this role will also remove users under this role. Are you sure you want to delete?",
        "text12": "Confirm",
        "text13": "Cancel",
        "text14": "Deleted Successfully",
        "text15": "Request Error",
        "text16": "Role Authorization",
        "text17": "Select All",
        "text18": "Operation Successful",
        "text19": "Edit Role",
        "text20": "View Role",
        "text21": "Parent Role:",
        "text22": "Role Name:",
        "text23": "Please enter the role name",
        "text24": "Role Description:",
        "text25": "Please enter the role description",
        "text26": "Selected Menus:",
        "text27": "Cancel",
        "text28": "Edit",
        "text29": "Confirm",
        "text30": "Input cannot exceed 10 characters",
        "text31": "Modification Successful",
        "text32": "Added Successfully"
    },
    global: {
        "text1": "Logout Successful",
        "text2": "Request Error",
        "text3": "Login Expired",
        "text4": "API Request Failed",
        "text5": "API Response Error"
    },
    router: {
        "text1": "Lango IoT Platform",
        "text2": "APP Download",
        "text3": "Lango IoT Platform",
        "text4": "Create Product",
        "text5": "Device Management",
        "text6": "OTA List",
        "text7": "Edit Product",
        "text8": "Device Debugging",
        "text9": "Application Debugging",
        "text10": "Authorization Service Details",
        "text11": "Device Logs",
        "text12": "Test",
        "text13": "Details",
        "text14": "Enterprise Certification",
        "text15": "Announcement Center",
        "text16": "Log Out",
        "text17": "Release",
        "text18": "Network Configuration Guide"
    },
    transaction: {
        text1: "Sorry, you can't find the page you visited",
        text2: "Return to Home"
    }

}
