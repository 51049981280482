import app from "../../main";

const device = {

    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {

        /**
         * 分页查询指定产品的调试设备列表,IOT平台调用
         * @param commit
         * @param data
         * @returns {Promise<void>}
         */
        async queryDebugDevicePager({commit}, data) {
            return await app.$api.device.queryDebugDevicePager(data);
        },

        /**
         * 分页查询指定产品的虚拟设备列表,IOT平台调用
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryVirtualDevicePager({commit}, data) {
            return await app.$api.device.queryVirtualDevicePager(data);
        },

        /**
         * 生成虚拟设备绑定码,IOT平台端调用
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async generateVirtualBindCode({commit}, data) {
            return await app.$api.device.generateVirtualBindCode(data);
        },

        /**
         * 查询虚拟设备绑定码是否还存在
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async isVirtualBindCodeAlive({commit}, data) {
            return await app.$api.device.isVirtualBindCodeAlive(data);
        },

        /**
         * 调试真机设备读取设备所有属性接口
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async getAllAttributes({commit}, data) {
            return await app.$api.control.getAllAttributes(data);
        },

        /**
         * 获取设备属性
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async getAttributes({commit}, data) {
            return await app.$api.control.getAttributes(data);
        },

        /**
         * 设置设备属性
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async setAttributes({commit}, data) {
            return await app.$api.control.setAttributes(data);
        },

        /**
         * 删除设备,IOT平台端调用，暂时只能删除调试和虚拟设备
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async deleteDevice({commit}, data) {
            return await app.$api.device.deleteDevice(data);
        },

        /**
         * 查询设备是否存在
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async checkDeviceExist({commit}, data) {
            return await app.$api.device.checkDeviceExist(data);
        },

        /**
         * 分页查询设备,IOT平台调用
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryDevice({commit}, data) {
            return await app.$api.device.queryDevice(data);
        },

        /**
         * 分页查询设备通信日志
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryMsgLog({commit}, data) {
            return await app.$api.device.queryMsgLog(data);
        },

        /**
         * IOT平台端设备管理界面设备量统计
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async deviceStatistics({commit}, data) {
            return await app.$api.device.deviceStatistics(data);
        }

    }

}

export default device;
