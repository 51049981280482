import service from "../../utils/request";

let url = "";
if (process.env.NODE_ENV === 'production') {
    url = "/lgiot/pdsgw";
} else {
    url = "/lgiot/product/pdsgw";
}

/**
 * 新建引导
 * @param {
 *     content 内容
 *     *productCode 产品Code
 *     *type 类型 1:配网引导
 * }
 * @returns {*}
 */
const addGuidance = data => {
    return service({
        url: url + '/guidance/add',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 引导查询接口
 * @param {
 *     *productCode 产品Code
 *     *type 类型 1:配网引导
 * }
 * @returns {*}
 */
const queryGuidanceList = data => {
    return service({
        url: url + '/guidance/queryList',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 修改引导
 * @param {
 *     content 内容
 *     *id 引导ID
 *     *productCode 产品Code
 *     *type 类型 1:配网引导
 * }
 * @returns {*}
 */
const updateGuidance = data => {
    return service({
        url: url + '/guidance/update',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

export default {
    addGuidance,
    queryGuidanceList,
    updateGuidance
}
