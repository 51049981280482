import service from "../../utils/request";

let url = "";
if (process.env.NODE_ENV === 'production') {
    url = "/lgiot/otsgw";
} else {
    url = "/lgiot/ota/otsgw";
}

/**
 * 查询OTA固件标识列表,IOT平台调用
 * @param data {
 *      *firmwareModel 固件标识
 *      pid
 * }
 * @returns {*}
 */
const queryFirmwareModelList = data => {
    return service({
        url: url + '/ota/queryFirmwareModelList',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 分页查询OTA固件列表,IOT平台调用
 * @param {
 *     beginCreateTime 创建开始时间 - 格式yyyy-MM-dd HH:mm:ss
 *     endCreateTime 创建结束时间 - 格式yyyy-MM-dd HH:mm:ss
 *     *firmwareModel 固件标识
 *     *currPage 当前页码
 *     firmwareType 固件类型 - 1:芯片固件;2:系统设置(废弃);3:Android (apk);4:配件(废弃);5:MacOS(pmg);6:Windows(exe);7:Linux (pkg);8:IOS;9:鸿蒙;10:WIFI模组固件
 *     isAsc 是否升序，true:升序; false:降序
 *     orderBy 排序字段 - version:版本号;publish_time:发布时间;
 *     *pageSize 每页返回记录数
 *     pid
 *     publishStatus 发布状态 - 0:未发布; 1:指定发布; 2:灰度发布; 3:全量发布
 * }
 * @returns {*}
 */
const queryPager = data => {
    return service({
        url: url + '/ota/queryPager',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 下架OTA固件,IOT平台调用
 * @param {
 *     *otaFileCode 固件编号
 * }
 * @returns {*}
 */
const banOTA = data => {
    return service({
        url: url + '/ota/ban',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 删除OTA固件,IOT平台调用
 * @param {
 *     *otaFileCode 固件编号
 * }
 * @returns {*}
 */
const delOTA = data => {
    return service({
        url: url + '/ota/delete',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 导入固件
 * @param {
 *     *downloadType 下载类型 - 0:前台下载; 1:静默下载
 *     *filePath 升级包存储路径
 *     *fileUrl 升级包FileUrl
 *     *firmwareModel 固件标识
 *     *installType 安装方式 - 0:强制安装; 1:非强制安装
 *     *pid
 *     reboot 安装重启 - true:是; false:否;
 *     *productType 产品类型 - 1:IOT平台产品; 2:非IOT平台产品
 *     relySdk 关联SDK, json格式 - [{"path":"com.lango.xbh.MyApp","v":"1.0.1"},{"path":"com.lango.funlink.MyApp","v":"1.0.1"}]
 *     triggerType 触发类型 - 0:设备主动(自检升级); 1:服务端触发升级; 2:移动端(App触发升级); 3:检测升级; 默认0
 *     versionDescribe 版本描述
 *     wipeArea 是否擦除分区json格式 - [{"k":"1","v":true},{"k":"2","v":false},{"k":"3","v":true}]
 *     wipeData 是否擦除数据分区 - true:是; false:否;
 *     wipeMedia 是否擦除媒体分区 - true:是; false:否;
 * }
 * @returns {*}
 */
const importFirmwareFile = data => {
    return service({
        url: url + '/ota/importFirmwareFile',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取OTA固件详情,IOT平台调用
 * @param {
 *     *otaFileCode 固件编号
 * }
 * @returns {*}
 */
const getOtaFileDetail = data => {
    return service({
        url: url + '/ota/getOtaFileDetail',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * OTA升级数据统计
 * @param {
 *     *otaFileCode 固件编号
 * }
 * @returns {*}
 */
const otaStatistic = data => {
    return service({
        url: url + '/otalog/otaStatistic',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 分页查询OTA日志列表,IOT平台调用
 * @param {
 *     beginCreateTime 开始时间 - 格式yyyy-MM-dd HH:mm:ss
 *     endCreateTime 结束时间 - 格式yyyy-MM-dd HH:mm:ss
 *     deviceId 设备ID
 *     *currPage 当前页码
 *     *pageSize 每页返回记录数
 *     *otaFileCode 固件编码
 *     status 状态 - 1:升级中; 2:升级完成;
 * }
 * @returns {*}
 */
const queryOTALogPager = data => {
    return service({
        url: url + '/otalog/queryPager',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}


/**
 * 修改固件下载链接地址，用于使用CDN加速下载,IOT平台调用
 * @param {
 *     channelCode 通道编号
 *     *fileUrl 下载地址
 *     *otaFileCode 固件编号
 * }
 * @returns {*}
 */
const modifyDownloadUrl = data => {
    return service({
        url: url + '/ota/modifyDownloadUrl',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * OTA固件发布,IOT平台调用
 * @param {
 *     *otaFileCode 固件编号
 *     planPublishTime 计划发布时间 - 格式yyyy-MM-dd HH:mm:ss
 *     *publishTimeType 发布时间类型 - 1:立即发布; 2:指定时间发布
 *     publishType 1:指定发布; 2:灰度发布; 3:全量发布
 *     uniqueKeys 唯一标识
 * }
 * @returns {*}
 */
const publishOTA = data => {
    return service({
        url: url + '/ota/publish',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 修改固件信息
 * @param {
 *     *downloadType 下载类型 - 0:前台下载; 1:静默下载
 *     *installType 安装方式 - 0:强制安装; 1:非强制安装
 *     *otaFileCode 固件编号
 *     *productType 产品类型 - 1:IOT平台产品; 2:非IOT平台产品
 *     reboot 安装重启 - true:是; false:否;
 *     relySdk 关联SDK, json格式 - [{"path":"com.lango.xbh.MyApp","v":"1.0.1"},{"path":"com.lango.funlink.MyApp","v":"1.0.1"}]
 *     triggerType 触发类型 - 0:设备主动(自检升级); 1:服务端触发升级; 2:移动端(App触发升级); 3:检测升级; 默认0
 *     versionDescribe 版本描述
 *     wipeArea 是否擦除分区json格式 - [{"k":"1","v":true},{"k":"2","v":false},{"k":"3","v":true}]
 *     wipeData 是否擦除数据分区 - true:是; false:否;
 *     wipeMedia 是否擦除媒体分区 - true:是; false:否;
 * }
 * @returns {*}
 */
const modifyFirmwareFile = data => {
    return service({
        url: url + '/ota/modifyFirmwareFile',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

export default {
    queryFirmwareModelList,
    queryPager,
    banOTA,
    delOTA,
    importFirmwareFile,
    getOtaFileDetail,
    otaStatistic,
    queryOTALogPager,
    modifyDownloadUrl,
    publishOTA,
    modifyFirmwareFile
}
