import app from "../../main";

const category = {

    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {

        /**
         * 查询所有的第一层级的节点
         * @param {*} param0
         * @returns
         */
        async queryFirstLevelCategory({commit}) {
            return await app.$api.category.queryFirstLevelCategory();
        },

        /**
         * 根据指定的节点编码查询它所有的子节点
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async queryChildsCategory({commit}, data) {
            return await app.$api.category.queryChildsCategory(data);
        },

        /**
         * 搜索品类树
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async searchCategoryTree({commit}, data) {
            return app.$api.category.searchCategoryTree(data);
        }

    }

}

export default category;
