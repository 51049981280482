import app from "../../main";
import {state} from "@antv/g2plot/lib/adaptor/common";

const maintain = {

    namespaced: true,

    state: {

        personnelManagementDialogVisible: false,

        addPersonnelDialogVisible: false,

        releaseFirmwareDialogVisible: false,

        addFirmwareTestDialogVisible: false,

        addWhiteListDialogVisible: false,

        addUserDialogVisible: false,

        addOTADrawerVisible: false,

        addOTADrawerType: 0, // 0：新增；1：修改；

        firmwareMsg: {}

    },

    getters: {

    },

    mutations: {

        SET_FIRMWARE_MSG: (state, firmwareMsg) => {
            state.firmwareMsg = firmwareMsg
        },

        SET_PERSONNEL_MANAGEMENT_DIALOG_VISIBLE: (state, personnelManagementDialogVisible) => {
            state.personnelManagementDialogVisible = personnelManagementDialogVisible
        },

        SET_ADD_PERSONNEL_DIALOG_VISIBLE: (state, addPersonnelDialogVisible) => {
            state.addPersonnelDialogVisible = addPersonnelDialogVisible;
        },

        SET_RELEASE_FIRMWARE_DIALOG_VISIBLE: (state, releaseFirmwareDialogVisible) => {
            state.releaseFirmwareDialogVisible = releaseFirmwareDialogVisible;
        },

        SET_ADD_FIRMWARE_TEST_DIALOG_VISIBLE: (state, addFirmwareTestDialogVisible) => {
            state.addFirmwareTestDialogVisible = addFirmwareTestDialogVisible;
        },

        SET_ADD_WHITE_LIST_DIALOG_VISIBLE: (state, addWhiteListDialogVisible) => {
            state.addWhiteListDialogVisible = addWhiteListDialogVisible;
        },

        SET_ADD_USER_DIALOG_VISIBLE: (state, addUserDialogVisible) => {
            state.addUserDialogVisible = addUserDialogVisible;
        },

        SET_ADD_OTA_DRAWER_VISIBLE: (state, addOTADrawerVisible) => {
            state.addOTADrawerVisible = addOTADrawerVisible;
        },

        SET_ADD_OTA_DRAWER_TYPE: (state, addOTADrawerType) => {
            console.log('addOTADrawerType SET_ADD_OTA_DRAWER_TYPE', addOTADrawerType)
            state.addOTADrawerType = addOTADrawerType;
        }

    },

    actions: {

        closeAddOTADrawer({commit}) {
            commit('SET_ADD_OTA_DRAWER_VISIBLE', false);
        },

        closePersonnelManagementDialog({commit}) {
            commit('SET_PERSONNEL_MANAGEMENT_DIALOG_VISIBLE', false);
        },

        closeAddPersonnelDialog({commit}) {
            commit('SET_ADD_PERSONNEL_DIALOG_VISIBLE', false);
        },

        closeReleaseFirmwareDialog({commit}) {
            commit('SET_RELEASE_FIRMWARE_DIALOG_VISIBLE', false);
        },

        closeAddFirmwareTestDialog({commit}) {
            commit('SET_ADD_FIRMWARE_TEST_DIALOG_VISIBLE', false);
        },

        closeAddWhiteListDialog({commit}) {
            commit('SET_ADD_WHITE_LIST_DIALOG_VISIBLE', false)
        },

        closeAddUserDialog({commit}) {
            commit('SET_ADD_USER_DIALOG_VISIBLE', false);
        },

        /**
         * 查询OTA固件标识列表,IOT平台调用
         * @param commit
         * @param data
         * @returns {*}
         */
        queryFirmwareModelList({commit}, data) {
            return app.$api.ota.queryFirmwareModelList(data);
        },

        /**
         * 分页查询OTA固件列表,IOT平台调用
         * @param commit
         * @param data
         * @returns {*}
         */
        queryPager({commit}, data) {
            return app.$api.ota.queryPager(data);
        },

        /**
         * 下架OTA固件,IOT平台调用
         * @param commit
         * @param data
         * @returns {*}
         */
        banOTA({commit}, data) {
            return app.$api.ota.banOTA(data);
        },

        /**
         * 删除OTA固件,IOT平台调用
         * @param commit
         * @param data
         * @returns {*}
         */
        delOTA({commit}, data) {
            return app.$api.ota.delOTA(data);
        },

        /**
         * 导入固件
         * @param commit
         * @param data
         * @returns {*}
         */
        importFirmwareFile({commit}, data) {
            return app.$api.ota.importFirmwareFile(data);
        },

        /**
         * 查询产品/别名列表
         * @param commit
         * @param data
         */
        queryProductAndAliasSimpleList({commit}, data) {
            return app.$api.product.queryProductAndAliasSimpleList(data);
        },

        /**
         * 新建产品昵称
         * @param commit
         * @param data
         * @returns {*}
         */
        addProductalias({commit}, data) {
            return app.$api.product.addProductalias(data);
        },

        /**
         * 获取OTA固件详情,IOT平台调用
         * @param commit
         * @param data
         * @returns {*}
         */
        getOtaFileDetail({commit}, data) {
            return app.$api.ota.getOtaFileDetail(data);
        },

        /**
         * OTA升级数据统计
         * @param commit
         * @param data
         * @returns {*}
         */
        otaStatistic({commit}, data) {
            return app.$api.ota.otaStatistic(data);
        },

        /**
         * 分页查询OTA日志列表,IOT平台调用
         * @param commit
         * @param data
         * @returns {*}
         */
        queryOTALogPager({commit}, data) {
            return app.$api.ota.queryOTALogPager(data);
        },

        /**
         * 修改固件下载链接地址，用于使用CDN加速下载,IOT平台调用
         * @param commit
         * @param data
         * @returns {*}
         */
        modifyDownloadUrl({commit}, data) {
            return app.$api.ota.modifyDownloadUrl(data);
        },

        /**
         * OTA固件发布,IOT平台调用
         * @param commit
         * @param data
         * @returns {*}
         */
        publishOTA({commit}, data) {
            return app.$api.ota.publishOTA(data);
        },

        /**
         * 修改固件信息
         * @param commit
         * @param data
         * @returns {*}
         */
        modifyFirmwareFile({commit}, data) {
            return app.$api.ota.modifyFirmwareFile(data);
        },

        /**
         * 获取服务器系统时间，单位毫秒
         * @param commit
         * @returns {*}
         */
        getServerTime({commit}) {
            return app.$api.user.getServerTime();
        }
    }

}

export default maintain;
