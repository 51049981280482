import service from "../../utils/request";
import data from "bootstrap/js/src/dom/data";

let url = "";
if (process.env.NODE_ENV === 'production') {
    url = "/lgiot/dvsgw";
} else {
    url = "/lgiot/device/dvsgw";
}

/**
 * 分页查询指定产品的调试设备列表,IOT平台调用
 * @param {
 *     *currPage 当前页码
 *     *pageSize 每页返回记录数
 *     *productCode 产品编号
 * }
 * @returns {*}
 */
const queryDebugDevicePager = data => {
    return service({
        url: url + '/device/queryDebugDevicePager',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 分页查询指定产品的虚拟设备列表,IOT平台调用
 * @param {
 *     *currPage 当前页码
 *     *pageSize 每页返回记录数
 *     *productCode 产品编号
 * }
 * @returns {*}
 */
const queryVirtualDevicePager = data => {
    return service({
        url: url + '/device/queryVirtualDevicePager',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 生成虚拟设备绑定码,IOT平台端调用
 * @param {
 *     *pid 产品PID
 * }
 * @returns {*}
 */
const generateVirtualBindCode = data => {
    return service({
        url: url + '/device/generateVirtualBindCode',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 查询虚拟设备绑定码是否还存在
 * @param {
 *     *bindcode 绑定码
 * }
 * @returns {*}
 */
const isVirtualBindCodeAlive = data => {
    return service({
        url: url + '/device/getVirtualBindCode',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 删除设备,IOT平台端调用，暂时只能删除调试和虚拟设备
 * @param {
 *     *deviceId 设备ID
 * }
 * @returns {*}
 */
const deleteDevice = data => {
    return service({
        url: url + '/device/deleteDevice',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 查询设备是否存在
 * @param {
 *     *deviceId 设备ID
 * }
 * @returns {*}
 */
const checkDeviceExist = data => {
    return service({
        url: url + '/device/checkDeviceExist',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 分页查询设备,IOT平台调用
 * @param {
 *    *currPage 当前页码
 *    *pageSize 每页返回记录数
 *    deviceId 设备ID
 *    isOnline 设备状态 - Y:在线; N:离线
 *    name 设备名称
 *    nenodeCode 子空间code
 *    ownerId 用户ID
 *    pid 产品PID
 *    spaceCode 空间code
 *    status 状态 - 1:未激活; 2:已激活
 *    type 设备类型 1.正常 2.虚拟 3.预览
 *    virtualId 虚拟ID，与设备ID必须传1个
 * }
 * @returns {*}
 */
const queryDevice = data => {
    return service({
        url: url + '/device/query',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 分页查询设备通信日志
 * @param {
 *     *currPage 当前页码
 *     *pageSize 每页返回记录数
 *     deliveryType 投递类型 - 1:下发; 2:上报
 *     deviceId 设备ID，与虚拟ID必须传1个
 *     startCreateTime 开始时间yyyy-MM-dd HH:mm:ss
 *     endCreateTime 结束时间yyyy-MM-dd HH:mm:ss
 *     logType 日志类型 - 1:通讯日志; 2:上下线日志; 3:绑定/解绑日志; 4:配网日志
 *     virtualId 虚拟ID，与设备ID必须传1个
 * }
 * @returns {*}
 */
const queryMsgLog = data => {
    return service({
        url: url + '/msglog/query',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * IOT平台端设备管理界面设备量统计
 * @param {
 *     pid 产品PID
 * }
 */
const deviceStatistics = data => {
    return service({
        url: url + '/device/devicestatistics',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 分页查询设备厂测结果统计 - 按工单号，IOT平台端调用
 * @param {
 *     address 地址
 *     *currPage 当前页码
 *     ip ip
 *     *pageSize 每页返回记录数
 *     *pid 产品pid
 * }
 * @returns {*}
 */
const queryDeviceStatisticsByOrderNo = data => {
    return service({
        url: url + '/openapi/v1/ft/queryDeviceStatisticsByOrderNo',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 查询工单详情 - 按工单号或者thenTime，IOT平台端调用
 * @param {
 *     orderNo 工单号
 *     *pid 产品pid
 *     theTime 时间，如:20231029;20231030;20231031
 *     ip 地址
 * }
 * @returns {*}
 */
const queryDeviceStatisticsItemByOrderNo = data => {
    return service({
        url: url + '/openapi/v1/ft/queryDeviceStatisticsItemByOrderNo',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}


const exportFactoryTestLog = data => {
    return service({
        url: url + '/openapi/v1/ft/exportFactoryTestLog',
        method: 'get',
        isToken: true,
        params: data,
        responseType: 'blob',
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 修改IP对应的地址, IOT平台端调用
 * @param {
 *     *address  地址
 *     *ip ip
 * }
 * @returns {*}
 */
const modifyIpAddress = data => {
    return service({
        url: url + '/openapi/v1/ft/modifyIpAddress',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 分页查询设备上下线结果统计 - IOT平台端调用
 * @param {
 *     *currPage 当前页码
 *     deviceId 设备ID
 *     isAsc 是否升序，true:升序;false:降序
 *     orderBy 排序字段 - online_times:上线次数
 *     *pageSize 每页返回记录数
 *     *categoryCode 品类编码
 *     *theDate 日期，格式:yyyy-MM-dd
 * }
 * @returns {*}
 */
const queryOnlineStatisticsByDay = data => {
    return service({
        url: url + '/openapi/v1/rp/queryOnlineStatisticsByDay',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取最近添加工单
 * @param {
 *     *generateDefault
 *     *pid
 * }
 * @returns {*}
 */
const querylatestorderno = data => {
    return service({
        url: url + '/openapi/v1/ft/querylatestorderno',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 根据工单号搜索
 * @param {
 *     *keyword
 * }
 * @returns {*}
 */
const queryorderno = data => {
    return service({
        url: url + '/openapi/v1/ft/queryorderno',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

export default {
    queryDebugDevicePager,
    queryVirtualDevicePager,
    generateVirtualBindCode,
    isVirtualBindCodeAlive,
    deleteDevice,
    checkDeviceExist,
    queryDevice,
    queryMsgLog,
    deviceStatistics,
    queryDeviceStatisticsByOrderNo,
    queryDeviceStatisticsItemByOrderNo,
    exportFactoryTestLog,
    modifyIpAddress,
    queryOnlineStatisticsByDay,
    querylatestorderno,
    queryorderno
}
