import app from "../../main";

const product = {

    namespaced: true,

    state: {

        //一级品类侧边栏开关
        addProductTypeDrawerVisible: false,

        //二三级品类侧边栏开关
        selectProductDrawerVisible: false,

        //一级品类选中值
        selectFirstCategory: {},

        //选中品类值
        selectCategoryMsg: {},

        //产品信息
        productMsg: {},

        //编辑产品基本信息侧边栏开关
        editProductDrawerVisible: false,

        //产品详情弹窗开关
        productMsgDialogVisible: false,

    },

    getters: {

        getSelectCategoryMsg(state) {
            return state.selectCategoryMsg;
        }

    },

    mutations: {

        SET_PRODUCT_MSG_DIALOG_VISIBLE: (state, productMsgDialogVisible) => {
            state.productMsgDialogVisible = productMsgDialogVisible;
        },

        SET_EDIT_PRODUCT_DRAWER_VISIBLE: (state, editProductDrawerVisible) => {
            state.editProductDrawerVisible = editProductDrawerVisible;
        },

        SET_ADD_PRODUCT_TYPE_DRAWER_VISIBLE: (state, addProductTypeDrawerVisible) => {
            state.addProductTypeDrawerVisible = addProductTypeDrawerVisible;
        },

        SET_SELECT_PRODUCT_DRAWER_VISIBLE: (state, selectProductDrawerVisible) => {
            state.selectProductDrawerVisible = selectProductDrawerVisible
        },

        SET_SELECT_FIRST_CATEGORY: (state, selectFirstCategory) => {
            state.selectFirstCategory = app.$config.deepCopy(selectFirstCategory);
        },

        SET_SELECT_CATEGORY_MSG: (state, selectCategoryMsg) => {
            state.selectCategoryMsg = app.$config.deepCopy(selectCategoryMsg);
        },

        SET_PRODUCT_MSG: (state, productMsg) => {
            state.productMsg = app.$config.deepCopy(productMsg);
        }

    },

    actions: {

        closeProductDialog({commit}) {
            commit("SET_PRODUCT_MSG_DIALOG_VISIBLE", false);
        },

        closeEditProductDrawer({commit}) {
            commit("SET_EDIT_PRODUCT_DRAWER_VISIBLE", false);
        },

        closeAddProductTypeDrawer({commit}) {
            commit("SET_ADD_PRODUCT_TYPE_DRAWER_VISIBLE", false);
        },

        closeSelectProductDrawer({commit}) {
            commit("SET_SELECT_PRODUCT_DRAWER_VISIBLE", false);
        },

        /**
         * 新建产品
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async addProduct({commit}, data) {
            return await app.$api.product.addProduct(data);
        },

        /**
         * 分页查询产品
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async queryProduct({commit}, data) {
            return await app.$api.product.queryProduct(data);
        },

        /**
         * 删除产品
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async deleteProduct({commit}, data) {
            return await app.$api.product.deleteProduct(data);
        },

        /**
         * 获取产品详情
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async getProductDetail({commit}, data) {
            await app.$api.product.getProductDetail(data).then(res => {
                res.result.isRefresh = data.isRefresh
                commit('SET_PRODUCT_MSG', res.result);
            }, err => {
                console.log(err);
                app.$dialog.showMessage(err.resultMsg, app.$config.TOAST_ERROR);
            });
        },

        /**
         * 修改产品
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async modifyProduct({commit}, data) {
            return await app.$api.product.modifyProduct(data);
        },

        /**
         * 扫码回调
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async scanCode({commit}, data) {
            return await app.$api.product.scanCode(data);
        },

        /**
         * 发布产品
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async publishProduct({commit}, data) {
            return await app.$api.product.publishProduct(data);
        },

        /**
         * 分页查询产品,IOT平台设备
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryDevice({commit}, data) {
            return await app.$api.product.queryDevice(data);
        },

        /**
         * 删除虚拟设备
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async delVirtualDevice({commit}, data) {
            return await app.$api.product.delVirtualDevice(data);
        },

        /**
         * 读取设备属性接口
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async getDeviceShadowToDebug({commit}, data) {
            return await app.$api.control.getDeviceShadowToDebug(data);
        },

        /**
         * 上报设备影子
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async setDeviceShadowToDebug({commit}, data) {
            return await app.$api.control.setDeviceShadowToDebug(data);
        },

        /**
         * 复制产品
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async copeProduct({commit}, data) {
            return await app.$api.product.copeProduct(data);
        },

        /**
         * 解绑真实设备
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async unBindPlatformDevice({commit}, data) {
            return await app.$api.control.unBindPlatformDevice(data);
        },

        /**
         * 配置服务查询接口
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryConfigureServiceList({commit}, data) {
            return await app.$api.product.queryConfigureServiceList(data);
        },

        /**
         * 跟据通信类型获取配网方式列表
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async getNetworkModeListByProtocolType({commit}, data) {
            return await app.$api.product.getNetworkModeListByProtocolType(data);
        }

    }

};

export default product;
