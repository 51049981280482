import app from "../../main";

const guidance = {

    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {

        /**
         * 新建引导
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async addGuidance({commit}, data) {
            return await app.$api.guidance.addGuidance(data);
        },

        /**
         * 引导查询接口
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryGuidanceList({commit}, data) {
            return await app.$api.guidance.queryGuidanceList(data);
        },

        /**
         * 修改引导
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async updateGuidance({commit}, data) {
            return await app.$api.guidance.updateGuidance(data);
        }

    }

}

export default guidance;
