import app from "../../main";


const factoryTest = {

    namespaced: true,

    state: {

        // 产测详情弹窗开关
        factoryTestMsgDrawerVisible: false,

        selectOrder: {}

    },

    getters: {},

    mutations: {
        SET_FACTORY_TEST_MSG_DRAWER_VISIBLE: (state, factoryTestMsgDrawerVisible) => {
            state.factoryTestMsgDrawerVisible = factoryTestMsgDrawerVisible;
        },

        SET_SELECT_ORDER: (state, selectOrder) => {
            state.selectOrder = selectOrder;
        }
    },

    actions: {

        closeFactoryTestMsgDrawer({commit}) {
            commit('SET_SELECT_ORDER', {})
            commit('SET_FACTORY_TEST_MSG_DRAWER_VISIBLE', false)
        },

        queryDeviceStatisticsByOrderNo({commit}, data) {
            return app.$api.device.queryDeviceStatisticsByOrderNo(data);
        },

        queryDeviceStatisticsItemByOrderNo({commit}, data) {
            return app.$api.device.queryDeviceStatisticsItemByOrderNo(data);
        },

        exportFactoryTestLog({commit}, data) {
            return app.$api.device.exportFactoryTestLog(data);
        },

        modifyIpAddress({commit}, data) {
            return app.$api.device.modifyIpAddress(data);
        },

        querylatestorderno({commit}, data) {
            return app.$api.device.querylatestorderno(data);
        },

        queryorderno({commit}, data) {
            return app.$api.device.queryorderno(data);
        }

    }

}

export default factoryTest;
