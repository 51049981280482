const tokenName = "accessToken";
const tokenName2 = "refreshToken";

/**
 * 获取token
 * @returns {string}
 */
function getToken() {
    return {
        accessToken: localStorage.getItem(tokenName),
        refreshToken: localStorage.getItem(tokenName2)
    }
}

/**
 * 设置token
 * @param token
 * @param refreshToken
 */
function setToken(token, refreshToken) {
    localStorage.setItem(tokenName, token)
    localStorage.setItem(tokenName2, refreshToken)
}

/**
 * 清空token
 */
function removeToken() {
    localStorage.setItem(tokenName, "")
    localStorage.setItem(tokenName2, "")
}

export default {
    getToken,
    setToken,
    removeToken
}
