import app from "../../main";

const main = {

    namespaced: true,

    state: {

        //通知详情侧边栏开关
        noticeMsgDialogVisible: false,

        //通知详情
        noticeMsg: {},

        //新建产品弹窗开关
        addProductDialogVisible: false,

        //复制产品弹窗开关
        copyProductDialogVisible: false,

        //产品详情
        productItemMsg: {}

    },

    getters: {},

    mutations: {

        SET_NOTICE_MSG_DIALOG_VISIBLE: (state, noticeMsgDialogVisible) => {
            state.noticeMsgDialogVisible = noticeMsgDialogVisible;
        },

        SET_NOTICE_MSG: (state, noticeMsg) => {
            state.noticeMsg = noticeMsg;
        },

        SET_ADD_PRODUCT_DIALOG_VISIBLE: (state, addProductDialogVisible) => {
            state.addProductDialogVisible = addProductDialogVisible;
        },

        SET_COPY_PRODUCT_DIALOG_VISIBLE: (state, copyProductDialogVisible) => {
            state.copyProductDialogVisible = copyProductDialogVisible;
        },

        SET_PRODUCTITEM_ITEM_MSG: (state, productItemMsg) => {
            state.productItemMsg = app.$config.deepCopy(productItemMsg);
        }

    },

    actions: {

        closeNoticeMsgDialog({commit}) {
            commit("SET_NOTICE_MSG", {});
            commit("SET_NOTICE_MSG_DIALOG_VISIBLE", false);
        },

        closeAddProductDialog({commit}) {
            commit("SET_ADD_PRODUCT_DIALOG_VISIBLE", false);
        },

        closeCopyProductDialog({commit}) {
            commit("SET_COPY_PRODUCT_DIALOG_VISIBLE", false);
            commit("SET_PRODUCTITEM_ITEM_MSG", {})
        },

        /**
         * 查询公告
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async queryNotice({commit}, data) {
            return await app.$api.product.queryNotice(data);
        },

        /**
         * 跟据公告code查询详情
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async getNoticeDetailByCode({commit}, data) {
            return await app.$api.product.getNoticeDetailByCode(data);
        },

        /**
         * 公告已读记录查询接口
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryReadNotice({commit}, data) {
            return await app.$api.product.queryReadNotice(data);
        },

        /**
         * 新建用户已读记录
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async addReadNotice({commit}, data) {
            return await app.$api.product.addReadNotice(data);
        },

        /**
         * 是否有未读公告消息
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async hasUnreadNotice({commit}, data) {
            return await app.$api.product.hasUnreadNotice(data);
        }

    }

}

export default main;
