import app from "../../main";

const release = {

    namespaced: true,

    state: {

        releaseDialogVisible: false,

    },

    getters: {},

    mutations: {

        SET_RELEASE_DIALOG_VISIBLE: (state, releaseDialogVisible) => {
            state.releaseDialogVisible = releaseDialogVisible;
        }

    },

    actions: {

        closeReleaseDialog({commit}) {
            commit("SET_RELEASE_DIALOG_VISIBLE", false);
        }

    }

}

export default release;
