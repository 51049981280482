import app from "../../main";

const empower = {

    namespaced: true,

    state: {

        //新增激活设备弹窗开关
        addActivationDialogVisible: false,

        //申请授权凭证抽屉开关
        applyAuthorizationVouchersDrawerVisible: false,

        //授权凭证抽屉类型 （0，申请授权 1，重新认证）
        applyAuthorizationVouchersDrawerType: 0,

        //授权申请详情
        applyAuthorizationVouchersMsg: {},

        //申请详情
        itemMsg: {},

        //导出License弹窗开关
        exportLicenseDialogVisible: false,

        //新增License弹窗开关
        addLicenseDialogVisible: false,

        //新增授权数量申请
        addAuthDialogVisible: false,

    },

    getters: {},

    mutations: {

        SET_ADD_ACTIVATION_DIALOG_VISIBLE: (state, addActivationDialogVisible) => {
            state.addActivationDialogVisible = addActivationDialogVisible
        },

        SET_APPLY_AUTHORIZATION_VOUCHERS_DRAWER_VISIBLE: (state, applyAuthorizationVouchersDrawerVisible) => {
            state.applyAuthorizationVouchersDrawerVisible = applyAuthorizationVouchersDrawerVisible;
        },

        SET_ITEM_MSG: (state, itemMsg) => {
            state.itemMsg = itemMsg;
        },

        SET_EXPORT_LICENSE_DIALOG_VISIBLE: (state, exportLicenseDialogVisible) => {
            state.exportLicenseDialogVisible = exportLicenseDialogVisible
        },

        SET_ADD_LICENSE_DIALOG_VISIBLE: (state, addLicenseDialogVisible) => {
            state.addLicenseDialogVisible = addLicenseDialogVisible;
        },

        SET_ADD_AUTH_DIALOG_VISIBLE: (state, addAuthDialogVisible) => {
            state.addAuthDialogVisible = addAuthDialogVisible;
        },

        SET_APPLY_AUTHORIZATION_VOUCHERS_DRAWER_TYPE: (state, applyAuthorizationVouchersDrawerType) => {
            state.applyAuthorizationVouchersDrawerType = applyAuthorizationVouchersDrawerType;
        },

        SET_APPLY_AUTHORIZATION_VOUCHERS_MSG: (state, applyAuthorizationVouchersMsg) => {
            state.applyAuthorizationVouchersMsg = applyAuthorizationVouchersMsg;
        }

    },

    actions: {

        closeAddActivationDialog({commit}) {
            commit('SET_ADD_ACTIVATION_DIALOG_VISIBLE', false);
            // commit('SET_ITEM_MSG', {});
        },

        closeApplyAuthorizationVouchersDrawer({commit}) {
            commit('SET_APPLY_AUTHORIZATION_VOUCHERS_DRAWER_VISIBLE', false);
            commit('SET_APPLY_AUTHORIZATION_VOUCHERS_MSG', {})
        },

        closeExportLicenseDialog({commit}) {
            commit('SET_EXPORT_LICENSE_DIALOG_VISIBLE', false);
        },

        closeAddLicenseDialog({commit}) {
            commit('SET_ADD_LICENSE_DIALOG_VISIBLE', false);
        },

        closeAddAuthDialog({commit}) {
            commit('SET_ADD_AUTH_DIALOG_VISIBLE', false);
        },

        /**
         * 新增授权申请
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async addAuthorization({commit}, data) {
            return app.$api.empower.addAuthorization(data);
        },

        /**
         * 授权列表查询
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryAuthorizationBusiness({commit}, data) {
            return app.$api.empower.queryAuthorizationBusiness(data);
        },

        /**
         * 新增导入授权成员
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async importMemberAuthorization({commit}, data) {
            return app.$api.empower.importMemberAuthorization(data);
        },

        /**
         * 授权列表查询
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryAuthorization({commit}, data) {
            return app.$api.empower.queryAuthorization(data);
        },

        /**
         * 下载设备凭证
         * @param commit
         * @returns {Promise<*>}
         */
        async exportDeviceCredentials({commit}, data) {
            return app.$api.empower.exportDeviceCredentials(data);
        },

        /**
         * 导出授权文件
         * @param commit
         * @returns {Promise<*>}
         */
        async exportAuthorizationFile({commit}, data) {
            return app.$api.empower.exportAuthorizationFile(data);
        },

        /**
         * 修改申请
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async updateAuth({commit}, data) {
            return app.$api.empower.updateAuth(data);
        },

        /**
         * 授权列表查询
         * @param commit
         * @returns {Promise<*>}
         */
        async statisticsAuth({commit}) {
            return app.$api.empower.statisticsAuth();
        },

        /**
         * 根据授权申请Code查询授权申请详情
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryAuthDetails({commit}, data) {
            return app.$api.empower.queryAuthDetails(data);
        },

        /**
         * 查询设备唯一标识列表
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryUniqueKey({commit}, data) {
            return app.$api.empower.queryUniqueKey(data);
        },

        /**
         * 查询License列表
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryLicense({commit}, data) {
            return app.$api.empower.queryLicense(data);
        },

        /**
         * 查询列表
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async queryMember({commit}, data) {
            return app.$api.empower.queryMember(data);
        },

        /**
         * 获取授权凭证
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async getAuthProductKeys({commit}, data) {
            return app.$api.empower.getAuthProductKeys(data);
        },

        /**
         * 导出license列表
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async exportLicense({commit}, data) {
            return await app.$api.empower.exportLicense(data);
        },

        /**
         * 生成license
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async generateLicense({commit}, data) {
            return await app.$api.empower.generateLicense(data);
        },

        /**
         * 新增授权数量申请
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async addIncreaseAuthorization({commit}, data) {
            return await app.$api.empower.addIncreaseAuthorization(data);
        },

        /**
         * 授权申请编号获取应用
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async getByAuthCode({commit}, data) {
            return await app.$api.empower.getByAuthCode(data);
        },

        /**
         * 导出唯一标识
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async exportuniquekey({commit}, data) {
            return await app.$api.empower.exportuniquekey(data);
        },

        /**
         * 重新认证(不支持传入excel文件)
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async reAddAuthorization({commit}, data) {
            return await app.$api.empower.reAddAuthorization(data);
        }

    }

}

export default empower;
