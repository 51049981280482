import app from "../../main";

const panel = {

    namespaced: true,

    state: {

        //新增面板弹窗开关
        addPanelDialogVisible: false,

    },

    getters: {},

    mutations: {

        SET_ADD_PANEL_DIALOG_VISIBLE: (state, addPanelDialogVisible) => {
            state.addPanelDialogVisible = addPanelDialogVisible;
        }

    },

    actions: {

        closeAddPanelDialog({commit}) {
            commit('SET_ADD_PANEL_DIALOG_VISIBLE', false);
        },

        /**
         * 新建应用
         * @param commit
         * @param data
         * @returns {Promise<*|Promise<*>|AxiosPromise>}
         */
        async addApply({commit}, data) {
            console.log(data)
            return app.$api.product.addApply(data);
        },

        /**
         * 删除应用
         * @param commit
         * @param data
         * @returns {Promise<*|Promise<*>|AxiosPromise>}
         */
        async delApply({commit}, data) {
            return app.$api.product.delApply(data);
        },

        /**
         * 发布应用
         * @param commit
         * @param data
         * @returns {Promise<*|Promise<*>|AxiosPromise>}
         */
        async pubApply({commit}, data) {
            return app.$api.product.pubApply(data);
        },

        /**
         * 应用查询接口
         * @param commit
         * @param data
         * @returns {Promise<*|Promise<*>|AxiosPromise>}
         */
        async queryApply({commit}, data) {
            return app.$api.product.queryApply(data);
        },

        /**
         * 应用查询接口
         * @param commit
         * @param data
         * @returns {Promise<*|Promise<*>|AxiosPromise>}
         */
        async queryApplyList({commit}, data) {
            return app.$api.product.queryApplyList(data);
        },

        /**
         * 查看App资源下载地址
         * @param commit
         * @returns {Promise<*>}
         */
        async getPanelResource({commit}, data) {
            return await app.$api.product.getPanelResource(data);
        },

        /**
         * 新建应用前检查
         * @param commit
         * @param data
         * @returns {Promise<void>}
         */
        async appLyAddCheck({commit}, data) {
            return await app.$api.product.appLyAddCheck(data);
        },

        /**
         * 生成预览码
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async generatePreviewCode({commit}, data) {
            return await app.$api.product.generatePreviewCode(data);
        }

    }

};

export default panel;
