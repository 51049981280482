import service from "../../utils/request";

let url = "";
if (process.env.NODE_ENV === 'production') {
    url = "/lgiot/pdsgw";
} else {
    url = "/lgiot/product/pdsgw";
}

/**
 * 查询所有的第一层级的节点
 * @returns
 */
const queryFirstLevelCategory = () => {
    return service({
        url: url + '/category/firstlevel',
        method: 'post',
        isToken: true,
        data: {},
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 根据指定的节点编码查询它所有的子节点
 * @param {
 *  code 品类编码（必填）
 * } data
 * @returns
 */
const queryChildsCategory = data => {
    return service({
        url: url + '/category/querychilds',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 搜索品类树
 * @param {
 *  *keyword 搜索
 * } data
 * @returns
 */
const searchCategoryTree = data => {
    return service({
        url: url + '/category/queryTree',
        method: 'post',
        isToken: true,
        data: data,
        contentType: 'application/json;charset=UTF-8'
    })
}

export default {
    queryFirstLevelCategory,
    queryChildsCategory,
    searchCategoryTree
}
