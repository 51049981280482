import app from "../../main";
import router from "../../router";
import { resetRouter } from "../../router/hook";

const menu = {

    namespaced:true,

    state:{

        addMenuDialogVisible: false, //添加菜单弹窗开关

        addMenuType: 1, //(1，编辑 2，新增一级菜单 3，新建二级菜单)

        menuMsgItem: {}, //选中菜单详情

        menuList: {

            homeMenuList: [],

            settingMenuList: []

        }, //原始菜单列表

    },
    getters:{

        /**
         * 获取设置菜单重定向路由
         * @param {*} state
         * @returns
         */
        getSetMenuRedirect(state){
            return state.menuList.setMenu[0] ? state.menuList.setMenu[0].uri : "";
        },

        /**
         * 获取首页用户菜单重定向路由
         * @param {*} state
         * @returns
         */
        getUserMenuRedirect(state){
            return state.menuList.userMenu[0] ? state.menuList.userMenu[0].uri : "";
        }

    },

    mutations:{

        SET_ADD_MENU_DIALOG_VISIBLE: (state,addMenuDialogVisible) => {
            state.addMenuDialogVisible = addMenuDialogVisible;
        },

        SET_ADD_MENU_TYPE: (state,addMenuType) => {
            state.addMenuType = addMenuType;
        },

        SET_MENU_MSG_ITEM: (state,menuMsgItem) => {
            state.menuMsgItem = app.$config.deepCopy(menuMsgItem);
        },

        SET_MENU_LIST: (state,menuList) => {
            state.menuList = app.$config.deepCopy(menuList);
        }

    },

    actions:{

        closeAddMenuDialog({commit}){
            commit("SET_ADD_MENU_DIALOG_VISIBLE",false);
            commit("SET_MENU_MSG_ITEM",{});
        },

        /**
         * 获取当前用户权限树
         * @param {*} param0
         */
        async getUserAuthorityTree({commit}){
            await app.$api.menu.getUserAuthorityTree().then(res => {

                let menuList = {};
                res.result.forEach((item, index) => {
                    if (item.code === "200") {
                        menuList.homeMenuList = [res.result[index]];
                    } else if (item.code === "201"){
                        menuList.settingMenuList = [res.result[index]];
                    }
                });
                commit("SET_MENU_LIST", menuList)

            },err => {
                console.log(err);
                commit("SET_MENU_LIST",{
                    homeMenuList: [],

                    settingMenuList: []
                });
            })
        }

    }

}

export default menu;
