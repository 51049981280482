// export zh from './lang/zh';
// export en from './lang/en';
export const zh = require('./lang/zh');
export const en = require('./lang/en');

const lang = {
    zh,
    en
}

export const t = (localeOverrides) => {
    let language = window.localStorage.getItem('language') ? window.localStorage.getItem('language') : 'en';
    let multilingual = lang[language]['default'];
    let localeOverrideList = localeOverrides.split('.');
    localeOverrideList.forEach(item => {
        multilingual = multilingual[item];
    })
    return multilingual;
}
