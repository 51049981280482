import service from "../../utils/request";

let url = "";
if (process.env.NODE_ENV === 'production') {
    url = "/lgiot/atsgw";
} else {
    url = "/lgiot/auth/atsgw";
}

/**
 * 获取当前登录的用户权限树，配合获取角色权限列表接口，用于分配权限给角色(只能分配自己拥有的权限)
 * @returns
 */
const getUserAuthorityTree = () => {
    return service({
        url: url + '/authority/getuserauthoritytree',
        method: 'post',
        isToken: true,
        data: {},
        contentType: 'application/json;charset=UTF-8'
    })
}

export default {
    getUserAuthorityTree
}
