import {Loading, Message, MessageBox, Notification} from 'element-ui';

/**
 * 加载框
 * @param title
 * @returns {ElLoadingComponent}
 */
function showLoading(title, domStr) {
    return Loading.service({
        target: domStr,
        lock: true,
        text: title,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
    })
}

/**
 * 消息提示
 * @param msg 提示内容
 * @param icon 弹窗类型（success/warning/info/error）
 * @returns {ElMessageComponent}
 */
function showMessage(msg, icon, duration = 2000) {
    if (!msg) {
        return null;
    }
    return Message({
        message: msg,
        type: icon == 1 ? 'success' : (icon == 2 ? 'warning' : (icon == 3 ? 'info' : 'error')),
        duration: duration,
        showClose: true
    });
}

/**
 * 确认框
 * @param content 询问内容
 * @param title 弹框标题
 * @param btn1 按钮1文本
 * @param btn2 按钮2文本
 * @param icon 弹框图标
 * @param btn1Callback 按钮1回调
 * @param btn2Callback 按钮2回调
 * @returns {Promise<MessageBoxData>}
 */
function showConfirm(opt = {
    content: "内容",
    title: "提示",
    btn1: "确认",
    btn2: "取消",
    icon: 3
}, btn1Callback, btn2Callback) {
    return MessageBox.confirm(opt.content, opt.title, {
        confirmButtonText: opt.btn1,
        cancelButtonText: opt.btn2,
        center: true,
        type: opt.icon == 1 ? 'success' : (opt.icon == 2 ? 'warning' : (opt.icon == 3 ? 'info' : (opt.icon == 4 ? 'error' : '')))
    }).then(btn1Callback).catch(btn2Callback);
}

/**
 * 通知框
 * @param title 标题
 * @param msg 内容
 * @param icon 类型
 * @returns {ElNotificationComponent}
 */
function showNotification(title, msg, icon, duration) {
    console.log('duration:', duration == -1 ? 0 : 3000)
    return Notification({
        title: title,
        message: msg,
        type: icon == 1 ? 'success' : (icon == 2 ? 'warning' : (icon == 3 ? 'info' : 'error')),
        offset: 90,
        duration: duration ? duration : 3000,
        dangerouslyUseHTMLString: true
    })
}

export default {
    showMessage,
    showConfirm,
    showLoading,
    showNotification
}



