//国际化
import * as i18n from '@/i18n/index';

/**
 * 个人中心菜单
 */
const userMenu = [
    {
        "code": "1",
        "parentCode": "1",
        "name": "个人信息",
        "level": 1,
        "isLeaf": "N",
        "uri": "/setting/userInfo",
        "children": []
    },
    {
        "code": "2",
        "parentCode": "2",
        "name": "企业管理",
        "level": 2,
        "isLeaf": "Y",
        "uri": "/setting/enterpriseInfo",
        "children": [
            {
                "code": "3",
                "parentCode": "2",
                "name": "成员管理",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/setting/member",
                "children": []
            }
        ]
    }
];

/**
 * 首页菜单
 */
const homeMenu = [
    {
        "code": "1",
        "parentCode": "0",
        "name": "产品开发",
        "level": 1,
        "isLeaf": "N",
        "uri": "",
        "children": [
            {
                "code": "10",
                "parentCode": "1",
                "name": "产品开发",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/index"
            },
            {
                "code": "11",
                "parentCode": "1",
                "name": "设备调试",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/debug"
            }
        ]
    },
    {
        "code": "2",
        "parentCode": "0",
        "name": "授权服务",
        "level": 1,
        "isLeaf": "N",
        "uri": "",
        "children": [
            {
                "code": "20",
                "parentCode": "2",
                "name": "授权服务",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/empower"
            }
        ]
    },
    {
        "code": "3",
        "parentCode": "200",
        "name": "运维服务",
        "level": 2,
        "isLeaf": "N",
        "uri": "",
        "children": [
            {
                "code": "30",
                "parentCode": "3",
                "name": "售后运维",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/afterSales",
                "pagePath": "@/views/maintain/afterSales"
            },
            {
                "code": "31",
                "parentCode": "3",
                "name": "设备日志",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/deviceLog",
                "pagePath": "@/views/maintain/deviceLog"
            },
            {
                "code": "32",
                "parentCode": "3",
                "name": "OTA升级",
                "level": 1,
                "isLeaf": "Y",
                "uri": "/ota",
                "pagePath": "@/views/maintain/ota"
            }
        ]
    },
]

/**
 * 通信协议字典
 */
const protocolTypeList = [
    {id: 1, name: 'WiFi'},
    {id: 2, name: i18n.t('message.protocolTypeList1')},
    {id: 3, name: 'WiFi-NFC'},
    {id: 4, name: i18n.t('message.protocolTypeList2')},
    // {id: 5, name: 'Zigbee'},
    // {id: 6, name: 'NB-IoT'},
    // { id: 7, name: 'LTE Cat.1' },
    // { id: 8, name: i18n.t('message.protocolTypeList3') },
    // { id: 9, name: 'LTE Cat.M' },
    // { id: 10, name: i18n.t('message.protocolTypeList4') },
    // {id: 11, name: 'GPRS'},
];


/**
 * 产品状态字典
 */
const productStatusList = [
    {id: 1, name: i18n.t('message.productStatusList1')},
    {id: 2, name: i18n.t('message.productStatusList2')},
    {id: 3, name: i18n.t('message.productStatusList3')}
]

/**
 * 产品状态颜色
 */
const productStatusColorList = [
    {id: 1, name: '#D46B08'},
    {id: 2, name: '#D46B08'},
    {id: 3, name: '#389E0D'}
]

/**
 * 认证企业状态列表
 */
const certifiedEnterpriseStatusList = [
    {id: 0, name: i18n.t('message.productStatusList1')},
    {id: 1, name: i18n.t('message.productStatusList2')},
    {id: 2, name: i18n.t('message.productStatusList3')}
]

/**
 * 升级方式
 */
const upgradeTypeList = [
    {id: -1, name: i18n.t('message.upgradeTypeList1')},
    {id: 0, name: "APK"},
    {id: 1, name: i18n.t('message.upgradeTypeList2')},
    {id: 2, name: "SDK"}
];

/**
 * 升级状态
 */
const upgradeStateList = [
    {id: 1, name: i18n.t('message.upgradeStateList1')},
    {id: 0, name: i18n.t('message.upgradeStateList2')}
];

/**
 * 地区列表
 */
const regionList = [
    {id: -1, name: i18n.t('message.upgradeTypeList1')},
    {id: 0, name: i18n.t('message.regionList2')},
    {id: 1, name: i18n.t('message.regionList3')},
    {id: 2, name: i18n.t('message.regionList4')}
]

/**
 * 比例列表
 */
const proportionList = [
    {id: 5, name: "5%"},
    {id: 10, name: "10%"},
    {id: 15, name: "15%"},
    {id: 20, name: "20%"},
    {id: 25, name: "25%"},
    {id: 30, name: "30%"}
]

/**
 * 发布方式
 */
const releaseModeList = [
    {id: -1, name: i18n.t('message.regionList1')},
    {id: 0, name: i18n.t('message.releaseModeList1')},
    {id: 1, name: i18n.t('message.releaseModeList2')},
    {id: 2, name: i18n.t('message.releaseModeList3')},
    {id: 2, name: i18n.t('message.releaseModeList4')},
]



/**
 * 属性格式字典
 */
const formatList = [
    {id: 0, name: i18n.t('message.formatList1')},
    // {id: 1, name: i18n.t('message.formatList2')},
    // {id: 2, name: i18n.t('message.formatList3')},
    {id: 3, name: i18n.t('message.formatList4')},
    {id: 4, name: i18n.t('message.formatList5')},
    {id: 5, name: i18n.t('message.formatList6')},
    {id: 6, name: i18n.t('message.formatList7')},
    {id: 7, name: i18n.t('message.formatList8')},
    // {id: 8, name: i18n.t('message.formatList9')},
    // {id: 9, name: i18n.t('message.formatList10')}
]

/**
 * 倍数列表
 */
const rateList = [
    {id: 0, name: '10^0'},
    {id: 1, name: '10^1'},
    {id: 2, name: '10^2'},
    {id: 3, name: '10^3'},
    {id: 4, name: '10^4'},
]

/**
 * 类型长度列表
 */
const typeByteList = [
    {id: 1, name: '1byte'},
    {id: 2, name: '2byte'},
    {id: 4, name: '4byte'}
]

/**
 * 属性格式字典
 */
const formatIDList = [
    {id: 0, name: 'Number'},
    // {id: 1, name: 'Integer'},
    // {id: 2, name: 'Float'},
    {id: 3, name: 'Enum'},
    {id: 4, name: 'Boolean'},
    {id: 5, name: 'String'},
    {id: 6, name: 'Raw'},
    {id: 7, name: 'Fault'},
    // {id: 8, name: 'Short'},
    // {id: 9, name: 'Long'}
]

/**
 * 访问权限字典
 */
const authorityList = [
    {id: 1, name: i18n.t('message.authorityList1')},
    {id: 2, name: i18n.t('message.authorityList2')},
    {id: 3, name: i18n.t('message.authorityList3')}
]

/**
 * 功能类别
 */
const effectTypeList = [
    {id: 1, name: i18n.t('message.effectTypeList1')},
    {id: 2, name: i18n.t('message.effectTypeList2')}
];

/**
 * 授权状态
 */
const empowerStatusList = [
    // {id: -2, name: i18n.t('message.empowerStatusList1')},
    // {id: -1, name: i18n.t('message.empowerStatusList2')},
    {id: 0, name: i18n.t('message.empowerStatusList3')},
    {id: 1, name: i18n.t('message.empowerStatusList4')},
    {id: 2, name: i18n.t('message.empowerStatusList5')}
];

/**
 * 设备激活状态
 */
const deviceStateList = [
    {id: 1, name: i18n.t('message.deviceStateList1')},
    {id: 2, name: i18n.t('message.deviceStateList2')}
];

/**
 * 设备类型
 */
const deviceTypeList = [
    {id: 1, name: i18n.t('message.deviceTypeList1')},
    {id: 2, name: i18n.t('message.deviceTypeList2')},
    {id: 3, name: i18n.t('message.deviceTypeList3')}
];

/**
 * 设备网络状态
 */
const deviceNetworkStateList = [
    {id: 0, name: i18n.t('message.upgradeTypeList1')},
    {id: 1, name: i18n.t('message.deviceNetworkStateList2')},
    {id: 2, name: i18n.t('message.deviceNetworkStateList3')}
]

/**
 * 工单类型
 */
const workOrderTypeList = [
    {id: 0, name: i18n.t('message.upgradeTypeList1')},
    {id: 1, name: i18n.t('message.workOrderTypeList1')},
    {id: 2, name: i18n.t('message.workOrderTypeList2')}
]

/**
 * 工单状态
 * @type {*[]}
 */
const workOrderStatesList = [
    {id: 0, name: i18n.t('message.upgradeTypeList1')},
    {id: 1, name: i18n.t('message.workOrderStatesList1')},
    {id: 2, name: i18n.t('message.workOrderStatesList2')},
    {id: 3, name: i18n.t('message.workOrderStatesList3')}
]

/**
 * 投递类型
 * @type {[{name: string, id: number},{name: string, id: number}]}
 */
const deliveryTypeList = [
    {id: 1, name: i18n.t('message.deliveryTypeList1')},
    {id: 2, name: i18n.t('message.deliveryTypeList2')},
]

/**
 * 日志类型
 * @type {[{name: string, id: number},{name: string, id: number},{name: string, id: number},{name: string, id: number}]}
 */
const logTypeList = [
    {id: 1, name: i18n.t('message.logTypeList1')},
    {id: 2, name: i18n.t('message.logTypeList2')},
    {id: 3, name: i18n.t('message.logTypeList3')},
    {id: 4, name: i18n.t('message.logTypeList4')},
]

/**
 * 授权对象
 */
const empowerObjectList = [
    {id: 1, name: i18n.t('message.empowerObjectList1')},
    {id: 2, name: i18n.t('message.empowerObjectList2')}
];

/**
 * 授权目的
 */
const empowerObjectiveList = [
    {id: 1, name: i18n.t('message.empowerObjectiveList1')},
    {id: 2, name: i18n.t('message.empowerObjectiveList2')},
    // { id: 3, name: i18n.t('message.empowerObjectiveList3') }
];

/**
 * 自动授权
 */
const autoAuthList = [
    {id: 1, name: i18n.t('message.autoAuthList1')},
    {id: 0, name: i18n.t('message.autoAuthList2')},
];

/**
 * 天线类型
 */
const antennaTypeList = [
    { id:1, name: i18n.t('message.antennaTypeList1')},
    { id:2, name: i18n.t('message.antennaTypeList2')},
    { id:9, name: i18n.t('message.antennaTypeList3')}
];

/**
 * 激活方式
 */
const activeTypeList = [
    { id:1, name: i18n.t('message.activeTypeList1')},
    { id:2, name: i18n.t('message.activeTypeList2')},
    { id:3, name: i18n.t('message.activeTypeList3')}
]

/**
 * 触发方式
 */
const triggerModeList = [
    {label: i18n.t('ota.text28'), value: 0, isDisabled: false},
    {label: i18n.t('ota.text29'), value: 1, isDisabled: false},
    {label: i18n.t('ota.text30'), value: 2, isDisabled: false},
    {label: i18n.t('ota.text31'), value: 3, isDisabled: false}
]

/**
 * 固件类型
 */
const firmwareTypeList =  [
    {
        value: 1,
        text: i18n.t('ota.text96')
    },
    {
        value: 3,
        text: "Android (apk)"
    },
    {
        value: 5,
        text: "MacOS(pmg)"
    },
    {
        value: 6,
        text: "Windows(exe)"
    },
    {
        value: 7,
        text: "Linux (pkg)"
    },
    {
        value: 8,
        text: "IOS"
    },
    {
        value: 9,
        text: i18n.t('ota.text97')
    },
    {
        value: 10,
        text: i18n.t('ota.text98')
    }
];

/**
 * 发布方式
 */
const publishStatusList = [
    {
        value: 0,
        text: i18n.t('ota.text16')
    },
    {
        value: 1,
        text: i18n.t('ota.text17')
    },
    {
        value: 2,
        text: i18n.t('ota.text18')
    },
    {
        value: 3,
        text: i18n.t('ota.text19')
    }
];

/**
 * 安装方式
 */
const installTypeList = [
    {label: i18n.t('ota.text99'), value: 0, isDisabled: false},
    {label: i18n.t('ota.text100'), value: 1, isDisabled: false},
]

/**
 * 升级状态
 */
const upgradeStatusList = [
    {label: i18n.t('ota.text101'), value: 1},
    {label: i18n.t('ota.text102'), value: 2},
    {label: i18n.t('ota.text103'), value: 3},
    {label: i18n.t('ota.text104'), value: 4}
]

/**
 * 是否为生产环境
 * @type {boolean}
 */
const isProduction = true;

/**
 * 文件上传地址
 */
const uploadImgUrl = isProduction ? '/lgiot/fisgw/file/web/upload' : '/lgiot/upload/fisgw/file/web/upload';

/**
 * 授权文件上传地址
 */
const uploadEmpowerUrl = isProduction ? '/lgiot/azsgw/authorization/readdata' : '/lgiot/empower/azsgw/authorization/readdata';

/**
 * 设备升级列表上传地址
 */
const deviceUpgradeUrl = isProduction ? '/lgiot/otsgw/ota/readuniquekey' : '/lgiot/ota/otsgw/ota/readuniquekey';

/**
 * 图片上传密钥
 */
const data = {
    appKey: '2264387516719142695',
    appSecret: 'bab32650-c2fd-4418-9dcc-5381ee30021b'
};

/**
 * 文档中心地址
 */
const docUrl = "http://doc.funlink.cloud";

/**
 * 调试说明文档
 * @type {string}
 */
const debugDocUrl = "http://doc.funlink.cloud/devicedebug";

/**
 * 授权管理批量导入设备唯一标识模板
 * @type {string}
 */
const empowerTemplateUrl = "https://oss.funlink.cloud/res/common/unique_key_template.xlsx";

export default {
    userMenu,
    homeMenu,
    empowerStatusList,
    empowerObjectList,
    empowerObjectiveList,
    certifiedEnterpriseStatusList,
    protocolTypeList,
    productStatusList,
    productStatusColorList,
    formatList,
    formatIDList,
    authorityList,
    effectTypeList,
    uploadImgUrl,
    data,
    docUrl,
    autoAuthList,
    deviceStateList,
    uploadEmpowerUrl,
    regionList,
    deviceNetworkStateList,
    workOrderTypeList,
    workOrderStatesList,
    logTypeList,
    upgradeTypeList,
    releaseModeList,
    proportionList,
    upgradeStateList,
    antennaTypeList,
    rateList,
    typeByteList,
    activeTypeList,
    deviceTypeList,
    deliveryTypeList,
    debugDocUrl,
    empowerTemplateUrl,
    firmwareTypeList,
    triggerModeList,
    publishStatusList,
    installTypeList,
    upgradeStatusList,
    deviceUpgradeUrl
}
