import service from "../../utils/request";
let url = "";
if (process.env.NODE_ENV === 'production') {
    url = "/lgiot/ctlgw";
} else {
    url = "/lgiot/control/ctlgw";
}

/**
 * 读取设备属性接口
 * @param {
 *   *deviceId 设备ID
 *   *serviceKey 功能Key
 *   *attributeKey 属性Key
 * }
 * @returns {AxiosPromise}
 */
const getDeviceShadowToDebug = data => {
    return service({
        url: url + '/device/shadow/dubug/get',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 上报设备影子
 * @param {
 *   *setParam 功能属性
 * }
 * @returns {AxiosPromise}
 */
const setDeviceShadowToDebug = data => {
    return service({
        url: url + '/device/shadow/dubug/set',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
};

/**
 * 解绑真实设备
 * @param {
 *     *deviceId 设备ID
 * }
 * @returns {*}
 */
const unBindPlatformDevice = data => {
    return service({
        url: url + '/platformDevice/unbind',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 调试真机设备读取设备所有属性接口
 * @param {
 *     *deviceId 设备ID
 * }
 * @returns {*}
 */
const getAllAttributes = data => {
    return service({
        url: url + '/device/debug/getAll',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取设备属性
 * @param {
 *     *deviceId 设备ID
 *     *serviceKey 服务标识
 * }
 * @returns {*}
 */
const getAttributes = data => {
    return service({
        url: url + '/device/debug/get',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 调试真机设备设置设备属性接口
 * @param {
 *     *deviceId 设备ID
 *     *instruction 属性JSON
 * }
 * @returns {*}
 */
const setAttributes = data => {
    return service({
        url: url + '/device/debug/set',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}


export default {
    getDeviceShadowToDebug,
    setDeviceShadowToDebug,
    unBindPlatformDevice,
    getAllAttributes,
    getAttributes,
    setAttributes
}
