
const setting = {

    namespaced: true,

    state: {

        updateTelDialogVisible:false,

        updatePasswordDialogVisible:false,

        updateUserDialogVisible:false,

        bindEmailOrTelDialogVisible:false,

        updateTelDialogType: 0, // 0 电话；1 邮箱；

        bindEmailOrTelType: 0, // 0 电话；1 邮箱；

    },

    getters: {},

    mutations: {

        SET_UPDATE_TEL_DIALOG_VISIBLE: (state, updateTelDialogVisible) => {
            state.updateTelDialogVisible = updateTelDialogVisible;
        },

        SET_UPDATE_PASSWORD_DIALOG_VISIBLE: (state, updatePasswordDialogVisible) => {
            state.updatePasswordDialogVisible = updatePasswordDialogVisible;
        },

        SET_UPDATE_USER_DIALOG_VISIBLE: (state, updateUserDialogVisible) => {
            state.updateUserDialogVisible = updateUserDialogVisible;
        },

        SET_UPDATE_TEL_DIALOG_TYPE: (state, updateTelDialogType) => {
            state.updateTelDialogType = updateTelDialogType;
        },

        SET_BIND_EMAIL_OR_TEL_DIALOG_VISIBLE: (state, bindEmailOrTelDialogVisible) => {
            state.bindEmailOrTelDialogVisible = bindEmailOrTelDialogVisible;
        },

        SET_BIND_EMAIL_OR_TEL_TYPE: (state, bindEmailOrTelType) => {
            state.bindEmailOrTelType = bindEmailOrTelType;
        }

    },

    actions:{

        closeUpdateTelDialog({commit}) {
            commit("SET_UPDATE_TEL_DIALOG_VISIBLE", false);
            commit("SET_UPDATE_TEL_DIALOG_TYPE", 0);
        },

        closeBindEmailOrTelDialog({commit}) {
            commit("SET_BIND_EMAIL_OR_TEL_DIALOG_VISIBLE", false);
            commit("SET_BIND_EMAIL_OR_TEL_TYPE", 0);
        },

        closeUpdatePasswordDialog({commit}) {
            commit("SET_UPDATE_PASSWORD_DIALOG_VISIBLE",false);
        },

        closeUpdateUserDialog({commit}) {
            commit("SET_UPDATE_USER_DIALOG_VISIBLE",false);
        }

    }

}

export default setting;
