import app from "../../main";

const member = {

    namespaced: true,

    state: {

        //成员弹窗开关
        userDialogVisible: false,

        //成员弹窗类型（0，添加  1，修改）
        userDialogType: 0,

        //单个成员详细信息
        userItemMsg: {},

        //移交企业弹窗开关
        transferEnterpriseDialogVisible: false,

        //选择产品弹窗开关
        selectProductDialogVisible: false,

        //选择移交用户弹窗开关
        userTableDialogVisible: false,

    },

    getters: {},

    mutations: {

        SET_USER_DIALOG_VISIBLE: (state, userDialogVisible) => {
            state.userDialogVisible = userDialogVisible;
        },

        SET_USER_DIALOG_TYPE: (state, userDialogType) => {
            state.userDialogType = userDialogType;
        },

        SET_USER_ITEM_MSG: (state, userItemMsg) => {
            state.userItemMsg = app.$config.deepCopy(userItemMsg);
        },

        SET_TRANSFER_ENTERPRISE_DIALOG_VISIBLE: (state, transferEnterpriseDialogVisible) => {
            state.transferEnterpriseDialogVisible = transferEnterpriseDialogVisible;
        },

        SET_SELECT_PRODUCT_DIALOG_VISIBLE: (state, selectProductDialogVisible) => {
            state.selectProductDialogVisible = selectProductDialogVisible;
        },

        SET_USER_TABLE_DIALOG_VISIBLE: (state, userTableDialogVisible) => {
            state.userTableDialogVisible = userTableDialogVisible;
        }

    },

    actions: {

        closeUserDialog({commit}) {
            commit("SET_USER_DIALOG_VISIBLE", false);
        },
        closeTransferEnterpriseDialog({commit}) {
            commit("SET_TRANSFER_ENTERPRISE_DIALOG_VISIBLE", false);
        },
        closeSelectProductDialog({commit}) {
            commit("SET_SELECT_PRODUCT_DIALOG_VISIBLE", false);
        },

        /**
         * 通过用户名查找用户基本信息，只返回用户ID和用户名
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async findUserBaseInfo({commit}, data) {
            return await app.$api.user.findUserBaseInfo(data);
        },

        /**
         * 新增企业成员
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async addMember({commit}, data) {
            return await app.$api.enterprise.addMember(data);
        },

        /**
         * 删除成员
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async deleteMember({commit}, data) {
            return await app.$api.enterprise.deleteMember(data);
        },

        /**
         * 根据code查询一条成员数据，包含产品、角色信息
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async getMemberDetail({commit}, data) {
            return await app.$api.enterprise.getMemberDetail(data);
        },

        /**
         * 修改成员
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async modifyMember({commit}, data) {
            return await app.$api.enterprise.modifyMember(data);
        },

        /**
         * iot平台端成员管理分页列表
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async queryMember({commit}, data) {
            return await app.$api.enterprise.queryMember(data);
        },

        /**
         * 根据企业id查询该企业的所有未删除人员
         * @param {*} param0
         * @returns
         */
        async queryMemberByInterpriseid({commit}) {
            return await app.$api.enterprise.queryMemberByInterpriseid();
        }

    }

}

export default member;
