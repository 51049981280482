import service from "../../utils/request";

let url = "";
if (process.env.NODE_ENV === 'production') {
    url = "/lgiot/etsgw";
} else {
    url = "/lgiot/enterprise/etsgw";
}

/**
 * 查询当前用户的所有处于启用状态的关系的企业列表名称
 * @returns
 */
const queryEnterpriseByUserId = () => {
    return service({
        url: url + '/enterprise/myenterprise/list',
        method: 'post',
        isToken: true,
        data: {},
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 新增企业认证
 * @param {
 *  *address 企业地址
 *  *businessLicence 营业执照
 *  *fullName 企业全称
 *  introduction 企业简介
 *  *legalHandIdcard 法人手持身份证照片
 *  *legalPerson 企业法人
 *  *shortName 企业简称
 *  *shortNameEn 英文简称
 * } data
 * @returns
 */
const addEnterprise = data => {
    return service({
        url: url + '/enterprise/add',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 企业管理后台分页查询接口
 * @param {
 *  *currPage 当前页码
 *  *pageSize 每页返回记录数
 *  param 查询参数
 * } data
 * @returns
 */
const queryEnterprise = data => {
    return service({
        url: url + '/enterprise/querybackpager',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 根据企业ID查询企业详情
 * @param {
 *  *enterpriseId 企业ID
 * } data
 * @returns
 */
const queryEnterpriseDetailById = data => {
    return service({
        url: url + '/enterprise/querydetailbyid',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * iot平台端企业列表查询
 * @param {
 *  *currPage 当前页码
 *  *pageSize 每页返回记录数
 * } data
 * @returns
 */
const queryEnterpriseToIotpager = data => {
    return service({
        url: url + '/enterprise/queryiotpager',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 企业移交
 * @param {
 *  *userId 成员id
 * } data
 * @returns
 */
const transferEnterprise = data => {
    return service({
        url: url + '/enterprise/transfer',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 新增企业成员
 * @param {
 *  *allProduct 所有产品 - 是否拥有所有产品管理权限 - Y:是; N:否; 如果取值为N,则productCode传指定产品编码
 *  productCodes 产品编码集合
 *  remark 备注名
 *  *roleCode 角色编码
 *  *userId 用户ID
 * } data
 * @returns
 */
const addMember = data => {
    return service({
        url: url + '/member/add',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 删除成员
 * @param {
 *  *code 成员编号
 * } data
 * @returns
 */
const deleteMember = data => {
    return service({
        url: url + '/member/delete',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 根据code查询一条成员数据，包含产品、角色信息
 * @param {
 *  *code 编号
 * } data
 * @returns
 */
const getMemberDetail = data => {
    return service({
        url: url + '/member/detail',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 修改成员
 * @param {
 *  *code 编号
 *  *productCodes 产品编码集合
 *  remark 备注名
 *  *roleCode 角色编码
 * } data
 * @returns
 */
const modifyMember = data => {
    return service({
        url: url + '/member/modify',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * iot平台端成员管理分页列表
 * @param {
 *  *currPage 当前页码
 *  *pageSize 每页返回记录数
 *  status 状态 - 0:禁用; 1:启用
 *  enterpriseId 企业ID
 *  keyword 备注名称
 * } data
 * @returns
 */
const queryMember = data => {
    return service({
        url: url + '/member/query',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 根据企业id查询该企业的所有未删除人员
 * @returns
 */
const queryMemberByInterpriseid = () => {
    return service({
        url: url + '/member/querybyinterpriseid',
        method: 'post',
        isToken: true,
        data: {},
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 企业申请认证信息修改–修改后转为待审核状态
 * @param {
 *     address 企业地址
 *     businessLicence 营业执照
 *     contactNumber 联系电话
 *     contacts 联系人
 *     *enterpriseId 企业ID
 *     fullName 企业全称
 *     introduction 企业简介
 *     legalHandIdcard 法人手持身份证照片
 *     legalPerson 企业法人
 *     shortName 企业简称
 *     shortNameEn 英文简称
 * }
 * @returns {*}
 */
const enterpriseModification = data => {
    return service({
        url: url + '/enterprise/informationModification',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

export default {
    queryEnterpriseByUserId,
    addEnterprise,
    queryEnterprise,
    queryEnterpriseDetailById,
    queryEnterpriseToIotpager,
    transferEnterprise,
    addMember,
    deleteMember,
    getMemberDetail,
    modifyMember,
    queryMember,
    queryMemberByInterpriseid,
    enterpriseModification
}
