import service from "../../utils/request";

let url = "";
if (process.env.NODE_ENV === 'production') {
    url = "/lgiot/atsgw";
} else {
    url = "/lgiot/auth/atsgw";
}

/**
 * 分页查询角色
 * @param {
 *  *currPage 当前页码
 *  name 角色name
 *  *pageSize 每页返回记录数
 *  parentCode 父角色编码
 * } data
 * @returns
 */
const queryRole = data => {
    return service({
        url: url + '/role/query',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取角色树
 * @returns
 */
const roleTree = () => {
    return service({
        url: url + '/role/tree',
        method: 'post',
        isToken: true,
        data: {},
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 新建角色
 * @param {
 *  *name 角色名
 *  parentCode 父角色编号
 *  remark 备注
 *  sortIndex 序号
 * } data
 * @returns
 */
const addRole = data => {
    return service({
        url: url + '/role/add',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 删除角色
 * @param {
 *  *code 角色编号
 * } data
 * @returns
 */
const deleteRole = data => {
    return service({
        url: url + '/role/delete',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 修改角色
 * @param {
 *  *code 角色编码
 *  *name 角色名
 *  remark 备注
 * } data
 * @returns
 */
const modifyRole = data => {
    return service({
        url: url + '/role/modify',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取角色拥有的权限列表
 * @param {
 *  *roleCode 角色编码
 * } data
 * @returns
 */
const getRoleauthorityList = data => {
    return service({
        url: url + '/roleauthority/list',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 取消角色权限选中
 * @param {
 *  *authorityCode 权限编码
 *  *roleCode 角色编码
 *  isAll 是否全选 Y / N
 * } data
 * @returns
 */
const pitchoffauthorityRoleauthority = data => {
    return service({
        url: url + '/roleauthority/pitchoffauthority',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 角色权限选中
 * @param {
 *  *authorityCode 权限编码
 *  *roleCode 角色编码
 *  isAll 是否全选 Y / N
 * } data
 * @returns
 */
const pitchonauthorityRoleauthority = data => {
    return service({
        url: url + '/roleauthority/pitchonauthority',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

/**
 * 获取角色拥有的权限树
 * @param {
 *  *roleCode 角色编码
 * } data
 * @returns
 */
const getAuthTreeByCode = data => {
    return service({
        url: url + '/roleauthority/listTree',
        method: 'post',
        isToken: true,
        data,
        contentType: 'application/json;charset=UTF-8'
    })
}

export default {
    queryRole,
    roleTree,
    addRole,
    deleteRole,
    modifyRole,
    getRoleauthorityList,
    pitchoffauthorityRoleauthority,
    pitchonauthorityRoleauthority,
    getAuthTreeByCode
}
